import { Box, Image, Text } from "@chakra-ui/react";
import { SetStateAction } from "react";
import ArrowIcon from "../icon/arrow-icon.svg";

interface PaginationPropsType {
  currentPage: number;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
  totalPage: number;
}

export default function Pagination({
  currentPage,
  setCurrentPage,
  totalPage,
}: PaginationPropsType) {
  const getPageNumbers = (currentPage: number, totalPages: number) => {
    const numToShow = 5; // 현재 페이지를 중심으로 보여줄 숫자 개수
    const half = Math.floor(numToShow / 2);

    let start = Math.max(1, currentPage - half);
    let end = Math.min(totalPages, start + numToShow - 1);

    start = Math.max(1, end - numToShow + 1);

    const pageNumbers = [];
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <Box
      display="grid"
      alignItems="center"
      gridTemplateColumns={"repeat(7, 48px)"}
      gap="8px"
    >
      <Box
        display="flex"
        justifyContent="center"
        cursor="pointer"
        onClick={() =>
          setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1)
        }
      >
        <Image
          src={ArrowIcon}
          w="16px"
          h="16px"
          opacity={currentPage === 1 ? 0.2 : 1}
        />
      </Box>
      {getPageNumbers(currentPage, totalPage).map((number) => (
        <Box
          key={number}
          display="flex"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          textAlign="center"
          borderRadius="50px"
          w="40px"
          h="40px"
          bgColor={
            currentPage === number ? "var(--chakra-colors-gray-100)" : "none"
          }
          color={
            currentPage === number ? "var(--chakra-colors-teal-500)" : "none"
          }
          fontWeight={currentPage === number ? "600" : "400"}
          onClick={() => setCurrentPage(number)}
        >
          <Text lineHeight="0.7" alignSelf="center" alignItems="center">
            {number}
          </Text>
        </Box>
      ))}
      <Box
        display="flex"
        justifyContent="center"
        cursor="pointer"
        onClick={() =>
          setCurrentPage(
            currentPage === totalPage ? currentPage : currentPage + 1,
          )
        }
      >
        <Image
          src={ArrowIcon}
          style={{ rotate: "180deg" }}
          w="16px"
          h="16px"
          opacity={currentPage === totalPage ? 0.2 : 1}
        />
      </Box>
    </Box>
  );
}
