import {
  Box,
  Button,
  FormControl,
  Text,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { SetStateAction, useEffect, useState } from "react";
import { AuctionPageStateType } from "../../pages/auction/Auction";
import { instance } from "../../apis/axios/instance";
import {
  reqPatchAuctionsAuctionIdType,
  reqPostAuctionsType,
  resGetAuctionsAuctionIdType,
  resPostAuctionsType,
} from "../../apis/types/auctions";
import {
  apiResponseCommonType,
  cyphersTierType,
  cyphersTierTypeOrder,
  gameType,
} from "../../apis/types/common";
import { useNavigate } from "react-router-dom";
import {
  AUCTION_ROOM_TEAM_COUNT,
  AUCTION_ROOM_TITLE_PATTERN,
} from "../../constants/regExp";
import { auctionCyphersTierType } from "./AuctionUser";
import { checkAuthenticationToken } from "../../utils/api";

export default function SettingAuction({
  currentAuctionId,
  setCurrentAuctionId,
  setPageState,
  isEditAuction,
}: {
  currentAuctionId: number;
  setCurrentAuctionId: React.Dispatch<SetStateAction<number>>;
  setPageState: React.Dispatch<SetStateAction<AuctionPageStateType>>;
  isEditAuction: boolean;
}) {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [gameName, setGameName] = useState<gameType>(gameType.사이퍼즈);
  const [teamCount, setTeamCount] = useState(0);
  const [memberCount, setMemberCount] = useState("5");
  const [biddingPoint, setBiddingPoint] = useState(1000);
  const [minTier, setMinTier] = useState<
    typeof auctionCyphersTierType | string
  >("NONE");
  const [maxTier, setMaxTier] = useState<
    typeof auctionCyphersTierType | string
  >("NONE");
  const [auctionData, setAuctionData] = useState<resGetAuctionsAuctionIdType>();

  const getAuctionsAuctionIdApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.get(`/v1/auctions/${currentAuctionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = response.data as resGetAuctionsAuctionIdType;
    if (result.resultCode === 200) {
      setAuctionData(result);
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const postAuctionsApiFetch = async (req: reqPostAuctionsType) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.post(`/v1/auctions`, req, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = response.data as resPostAuctionsType;
    if (result.resultCode === 200) {
      setPageState("AUCTION_USER_SETTING");
      setCurrentAuctionId(result.data.auctionId);
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const patchAuctionsApiFetch = async (req: reqPatchAuctionsAuctionIdType) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.patch(
      `/v1/auctions/${currentAuctionId}`,
      req,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 200) {
      setPageState("AUCTION_USER_SETTING");
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const deleteAuctionsApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.delete(`/v1/auctions/${currentAuctionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 200) {
      alert("경매가 삭제되었습니다. 경매 리스트로 이동합니다.");
      setPageState("AUCTION_LIST");
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const auctionDataValidation = () => {
    if (!AUCTION_ROOM_TITLE_PATTERN.test(title))
      return alert("경매 방 제목은 특수문자 제외 6 ~ 15자이내로 작성해주세요.");
    if (!AUCTION_ROOM_TEAM_COUNT.test(String(teamCount)))
      return alert("2 ~ 8팀 중 입력해주세요.");
    if (isEditAuction && currentAuctionId !== 0)
      return patchAuctionsApiFetch({
        title,
        teamCount,
        memberCount: Number(memberCount),
        biddingPoint,
        minTier: minTier as cyphersTierType,
        maxTier: maxTier as cyphersTierType,
      });
    else {
      return postAuctionsApiFetch({
        title,
        gameName,
        teamCount,
        memberCount: Number(memberCount),
        biddingPoint,
        minTier: minTier as cyphersTierType,
        maxTier: maxTier as cyphersTierType,
      });
    }
  };

  useEffect(() => {
    if (isEditAuction && currentAuctionId !== 0) {
      getAuctionsAuctionIdApiFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditAuction, currentAuctionId]);

  useEffect(() => {
    if (auctionData) {
      const { title, teamCount, gameName, memberCount, minTier, maxTier } =
        auctionData.data;
      setTitle(title);
      setTeamCount(teamCount);
      setGameName(gameName);
      setMemberCount(String(memberCount));
      setMinTier(minTier);
      setMaxTier(maxTier);
    }
  }, [auctionData]);

  return (
    <Box w="100%" textAlign="center">
      <Box display="grid" gap="16px">
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns={
            isEditAuction && currentAuctionId !== 0
              ? "2.3fr 8fr 2fr"
              : "2.3fr 10fr"
          }
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            제목
          </FormLabel>
          <Input
            size="lg"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {isEditAuction && currentAuctionId !== 0 ? (
            <Button
              size="lg"
              ml="16px"
              colorScheme="red"
              variant="outline"
              onClick={() => {
                if (window.confirm("해당 경매를 정말 삭제하시겠습니까?")) {
                  deleteAuctionsApiFetch();
                }
              }}
            >
              경매 삭제
            </Button>
          ) : null}
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="2.3fr 10fr"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            게임
          </FormLabel>
          <RadioGroup
            onChange={(value) => setGameName(value as gameType)}
            value={gameName}
          >
            <Stack direction="row" gap="24px">
              <Radio
                p="8px 0"
                size="lg"
                value={gameType.사이퍼즈}
                w="200px"
                isReadOnly={currentAuctionId ? true : false}
                isDisabled={currentAuctionId ? true : false}
              >
                사이퍼즈
              </Radio>
              <Radio
                p="8px 0"
                size="lg"
                value="LoL"
                w="200px"
                isReadOnly={true}
                isDisabled={true}
              >
                LoL
              </Radio>
              <Radio
                p="8px 0"
                size="lg"
                value="오버워치"
                w="200px"
                isReadOnly={true}
                isDisabled={true}
              >
                오버워치
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="2.3fr 1.4fr 8.6fr"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            총 팀 수
          </FormLabel>
          <Input
            size="lg"
            type="number"
            textAlign="right"
            w="140px"
            placeholder="0"
            value={teamCount}
            onChange={(e) => setTeamCount(Number(e.target.value))}
          />
          <Text textAlign="left">팀</Text>
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="2.3fr 10fr"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            팀당 인원 수 (명)
          </FormLabel>
          <RadioGroup
            onChange={(value) => setMemberCount(value)}
            value={memberCount}
          >
            <Stack direction="row" gap="24px">
              <Radio p="8px 0" size="lg" value={"5"} w="200px">
                5 명
              </Radio>
              <Radio p="8px 0" size="lg" value={"6"} w="200px">
                6 명
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="2.3fr 1.4fr 8.6fr"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            시작 경매 포인트
          </FormLabel>
          <Input
            size="lg"
            type="number"
            textAlign="right"
            w="140px"
            placeholder="0"
            value={biddingPoint}
            onChange={(e) => setBiddingPoint(Number(e.target.value))}
            isReadOnly
            isDisabled
          />
          <Text textAlign="left">포인트</Text>
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="2.3fr 10fr"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            최소 티어
          </FormLabel>
          <RadioGroup
            onChange={(value) => setMinTier(value)}
            value={minTier as string}
          >
            <Stack direction="row" gap="24px">
              {cyphersTierTypeOrder.flatMap((item) => (
                <Radio key={item} p="8px 0" size="lg" value={item} w="100px">
                  {item}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="2.3fr 10fr"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            최대 티어
          </FormLabel>
          <RadioGroup
            onChange={(value) => setMaxTier(value)}
            value={maxTier as string}
          >
            <Stack direction="row" gap="24px">
              {cyphersTierTypeOrder.flatMap((item) => (
                <Radio key={item} p="8px 0" size="lg" value={item} w="100px">
                  {item}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
        <Text
          w="100%"
          p="16px 0 48px 0"
          textAlign="left"
          fontSize="16px"
          color="var(--chakra-colors-red-500)"
        >
          * 최소/최대 티어 제한은 현재 티어 기준입니다.
        </Text>
      </Box>
      {isEditAuction ? (
        <Button
          w="50%"
          size="lg"
          colorScheme="teal"
          onClick={auctionDataValidation}
        >
          수정 후 다음
        </Button>
      ) : (
        <Button
          w="50%"
          size="lg"
          colorScheme="teal"
          onClick={auctionDataValidation}
        >
          저장 후 다음
        </Button>
      )}
    </Box>
  );
}
