import { Heading, Box, HStack, Text, Image, Badge } from "@chakra-ui/react";
import { instance } from "../../apis/axios/instance";
import {
  resGetAuctionsAuctionIdMembersType,
  resGetAuctionsAuctionIdTeamsType,
} from "../../apis/types/auctions";
import { useEffect, useState } from "react";
import { memberType, userTeamRoleType } from "../../apis/types/common";
import UserIcon from "../../icon/default-pepe-icon.png";
import { IMAGE_BASE_URL } from "../../constants/url";
import { checkAuthenticationToken } from "../../utils/api";
import { useNavigate } from "react-router-dom";

type teamType = {
  auctionTeamId: number;
  auctionId: number;
  teamName: string;
  remainingPoint: number;
};

export default function AuctionResultModalContext({
  auctionId,
}: {
  auctionId: number;
}) {
  const navigate = useNavigate();
  const [teams, setTeams] = useState<teamType[]>();
  const [auctionMembers, setAuctionMembers] = useState<memberType[]>();
  const [auctionTitle, setAuctionTitle] = useState("");

  useEffect(() => {
    const getAuctionsTeamsApiFetch = async () => {
      const accessToken = localStorage.getItem("accessToken");

      const response = await instance.get(`/v1/auctions/${auctionId}/teams`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const result = response.data as resGetAuctionsAuctionIdTeamsType;
      if (result.resultCode === 200) {
        setTeams(result.data);
      } else {
        checkAuthenticationToken(result, navigate);
      }
    };

    const getAuctionsAuctionIdMembersApiFetch = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const response = await instance.get(`/v1/auctions/${auctionId}/members`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const result = response.data as resGetAuctionsAuctionIdMembersType;
      if (result.resultCode === 200) {
        setAuctionMembers(result.data);
        setAuctionTitle(result.data[0].auctionTitle);
      } else {
        checkAuthenticationToken(result, navigate);
      }
    };

    getAuctionsAuctionIdMembersApiFetch();
    getAuctionsTeamsApiFetch();
  }, [auctionId, navigate]);

  if (!teams) return <></>;

  return (
    <Box
      display="flex"
      flexDir="column"
      w="100%"
      gap="24px"
      justifyContent="space-between"
    >
      <Heading size="lg" color="teal">
        "{auctionTitle}" 경매가 종료되었습니다.
      </Heading>
      <Box display="flex" flexDir="column" gap="24px" paddingTop="24px">
        {teams.map((team, idx) => {
          const selectMembers = auctionMembers?.filter(
            (member) => member.auctionTeamId === team.auctionTeamId
          );
          const leader = selectMembers?.find(
            (data) => data.teamRole === userTeamRoleType.LEADER
          );
          const members =
            selectMembers?.filter(
              (data) => data.teamRole === userTeamRoleType.MEMBER
            ) || [];
          const teamMembersData = [leader, ...members];

          return (
            <Box
              key={team.auctionTeamId}
              display="flex"
              flexDir="column"
              gap="16px"
              margin="0 auto"
            >
              <Heading size="md">
                {team.teamName} 
                <Badge
                  p="0px 8px"
                  m="0 8px"
                  fontSize="16px"
                  colorScheme="green"
                > 
                  잔여
                  {team.remainingPoint} p
                </Badge></Heading>
              <HStack>
                {teamMembersData.map((member, idx) => {
                  const isLeader = member?.teamRole === userTeamRoleType.LEADER;
                  if (!member) return <></>;
                  return (
                    <Box
                      key={idx}
                      w="180px"
                      display="flex"
                      flexDir="column"
                      alignItems="center"
                      position="relative"
                    >
                      {isLeader && (
                        <Box
                          top="0"
                          left="30px"
                          bgColor="var(--chakra-colors-red-500)"
                          color="var(--chakra-colors-white)"
                          position="absolute"
                          p="1px 2px"
                          zIndex={9}
                        >
                          <Text fontSize="14px" fontWeight="600">
                            팀장
                          </Text>
                        </Box>
                      )}
                      <Image
                        src={
                          member.profileIconURL
                            ? IMAGE_BASE_URL + member.profileIconURL
                            : UserIcon
                        }
                        alt="아이콘"
                        w="120px"
                        h="120px"
                        border={
                          isLeader
                            ? "4px solid var(--chakra-colors-red-500)"
                            : ""
                        }
                      />
                      <Box
                        w="180px"
                        display="flex"
                        justifyContent="center"
                        marginTop="16px"
                      >
                        <Text fontSize="16px" textAlign="center">
                          {member.nickname}
                        </Text>
                        {!isLeader && (
                          <Badge
                            p="0px 8px"
                            m="0 8px"
                            fontSize="16px"
                            colorScheme="green"
                          >
                            {member.biddingPrice
                              ? member.biddingPrice + " P"
                              : "유찰"}
                          </Badge>
                        )}
                      </Box>
                      <Text fontSize="12px" textAlign="center">
                          ({member.profileResponse.inGameNickname})
                        </Text>
                    </Box>
                  );
                })}
              </HStack>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
