import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import SignIn from "./pages/authorization/SignIn";
import SignUp from "./pages/authorization/SignUp";
import Auction from "./pages/auction/Auction";
import AppWrapper from "./components/AppWrapper";
import Profile from "./pages/profile/Profile";
import FindPassword from "./pages/authorization/FindPassword";
// import Notice from "./components/Notice";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Profile />,
      },
    ],
  },
  {
    path: "/signin",
    element: <AppWrapper />,
    children: [
      {
        index: true,
        // element: <Notice />,
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/signup",
    element: <AppWrapper />,
    children: [
      {
        index: true,
        // element: <Notice />,
        element: <SignUp />,
      },
    ],
  },
  {
    path: "/findPassword",
    element: <AppWrapper />,
    children: [
      {
        index: true,
        // element: <Notice />,
        element: <FindPassword />,
      },
    ],
  },
  {
    path: "/auction",
    element: <App />,
    children: [
      {
        index: true,
        element: <Auction />,
      },
    ],
  },
]);
