import { NavigateFunction } from "react-router-dom";
import { instance } from "../apis/axios/instance";
import { apiResponseCommonType } from "../apis/types/common";

const apiFailedHandler = (
  navigate: NavigateFunction,
  result?: apiResponseCommonType<{} | null>
) => {
  alert(result ? result.resultMessage : "잘못된 접근입니다.");
  localStorage.clear();
  return navigate("/signin");
};

/** AT,RT 재발행 */
const getTokenApiFetch = async (
  navigate: NavigateFunction,
  redirectPath: string | undefined
) => {
  const currentAccesToken = localStorage.getItem("accessToken");
  const currentRefreshToken = localStorage.getItem("refreshToken");
  const response = await instance.get(`/v1/token`, {
    headers: {
      Authorization: `Bearer ${currentAccesToken}`,
      "Refresh-Token": currentRefreshToken,
    },
  });
  const result = response.data as apiResponseCommonType<{} | null>;
  if (result.resultCode === 200) {
    const accessToken = response.headers["authorization"].split(" ")[1];
    const refreshToken = response.headers["refresh-token"];
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    redirectPath && window.location.replace(redirectPath);
  } else {
    apiFailedHandler(navigate, result);
  }
};

export const checkAuthenticationToken = (
  result: apiResponseCommonType<{} | null>,
  navigate: NavigateFunction,
  redirectPath?: string
) => {
  const { resultCode, resultMessage } = result;
  switch (resultCode) {
    case 401:
      return apiFailedHandler(navigate);
    case 427:
      return getTokenApiFetch(navigate, redirectPath);
    default:
      return alert(resultMessage);
  }
};
