import { Stack, Radio, RadioGroup, Box, Text } from "@chakra-ui/react";
import { cyphersPositionType, cyphersTierType } from "../../apis/types/common";

export default function ProfileRadioGroup<
  T extends string | cyphersTierType | cyphersPositionType,
>({
  radioText,
  radioContents,
  radioValue,
  setRadioValue,
  isAllRadioReadOnly,
}: {
  radioText: string;
  radioContents: T[];
  radioValue: T;
  setRadioValue: React.Dispatch<React.SetStateAction<T>>;
  isAllRadioReadOnly: boolean;
}) {
  return (
    <>
      <Text p="8px 0">{radioText}</Text>
      <Box>
        <RadioGroup
          onChange={(value) => setRadioValue(value as T)}
          value={radioValue as string}
        >
          <Stack direction="row" gap="24px">
            {radioContents.map((contents) => (
              <Radio
                p="8px 0"
                isReadOnly={isAllRadioReadOnly}
                isDisabled={isAllRadioReadOnly}
                key={contents}
                size="lg"
                value={contents as string}
                w="140px"
              >
                {contents}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </Box>
    </>
  );
}
