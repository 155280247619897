import { Box } from "@chakra-ui/react";

export default function Toast({
  isToastOpen,
  toastText = "",
}: {
  isToastOpen: boolean;
  toastText: string;
}) {
  return (
    <Box
      visibility={isToastOpen ? "visible" : "hidden"}
      opacity={isToastOpen ? 0.8 : 0}
      bgColor="teal"
      color="white"
      fontSize="16px"
      fontWeight="600"
      position="absolute"
      top="440px"
      left="50%"
      transition="opacity 0.2s ease-in-out, visibility 0.2s ease-in-out"
      borderRadius="24px"
      padding="8px 24px"
    >
      {toastText}
    </Box>
  );
}
