import axios from "axios";
import { API_BASE_URL } from "../../constants/url";

export const formDataInstance = axios.create({
  baseURL: API_BASE_URL,
  /**
   * https://github.com/axios/axios/blob/main/README.md#request-config
   * `timeout` specifies the number of milliseconds before the request times out.
   * If the request takes longer than `timeout`, the request will be aborted.
   */
  timeout: 0,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const instance = axios.create({
  baseURL: API_BASE_URL,
  /**
   * https://github.com/axios/axios/blob/main/README.md#request-config
   * `timeout` specifies the number of milliseconds before the request times out.
   * If the request takes longer than `timeout`, the request will be aborted.
   */
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
  },
});
