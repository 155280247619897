import { CompatClient } from "@stomp/stompjs";

export default function wsApi({
  client,
  memberId,
  auctionId,
  userId,
}: {
  client: CompatClient;
  memberId: number;
  auctionId: number;
  userId: number;
}) {
  /** 멤버 입장 ws 이벤트 */
  const sendEnterMessage = (client: CompatClient, memberId: number) => {
    const auctionMessage = {
      command: "enter",
      auctionId,
      memberId, // host는 memberId가 0
    };
    client.send("/app/auction/enter", {}, JSON.stringify(auctionMessage));
  };

  /** 경매 시작 ws 이벤트 */
  const sendStartMessage = () => {
    const auctionMessage = {
      command: "start",
      auctionId,
      userId,
    };
    client.send("/app/auction/start", {}, JSON.stringify(auctionMessage));
  };

  /** 포인트 입찰 ws 이벤트 */
  const sendBiddingMessage = (
    usePoint: number,
    targetId: number,
    bidder: number
  ) => {
    const auctionMessage = {
      command: "bidding",
      auctionId,
      usePoint,
      targetId,
      bidder,
    };
    client.send("/app/auction/bidding", {}, JSON.stringify(auctionMessage));
  };

  /** 전체 채팅 보내기 ws 이벤트 */
  const sendAllChatMessage = (chat: string) => {
    const auctionMessage = {
      command: "chat",
      auctionId,
      memberId,
      chat,
    };
    client.send("/app/auction/chat", {}, JSON.stringify(auctionMessage));
  };

  /** 팀 채팅 보내기 ws 이벤트 */
  const sendTeamChatMessage = (chat: string) => {
    const auctionMessage = {
      command: "chat",
      auctionId,
      memberId,
      chat,
    };
    client.send("/app/auction/teamChat", {}, JSON.stringify(auctionMessage));
  };

  /** 경매 생성 command */
  const sendCreateMessage = () => {
    const userId = window.localStorage.getItem("userId");
    const auctionMessage = {
      command: "create",
      auctionId,
      userId,
    };
    client.send("/app/auction/create", {}, JSON.stringify(auctionMessage));
  };

  return {
    sendEnterMessage,
    sendBiddingMessage,
    sendAllChatMessage,
    sendStartMessage,
    sendTeamChatMessage,
    sendCreateMessage,
  };
}
