import { Divider, Heading, Stack } from "@chakra-ui/react";

export default function ContentsLayout({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <Stack p="24px" gap="24px">
      <Heading>{title}</Heading>
      <Divider />
      {children}
    </Stack>
  );
}
