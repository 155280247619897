import React, { SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Image,
  Tfoot,
  useDisclosure,
} from "@chakra-ui/react";
import RefreshIcon from "../../icon/refresh-icon.svg";
import Pagination from "../Pagination";
import { AuctionPageStateType } from "../../pages/auction/Auction";
import { instance } from "../../apis/axios/instance";
import {
  resGetAuctionsType,
  resPostAuctionsAuctionIdEnterType,
} from "../../apis/types/auctions";
import { useNavigate } from "react-router-dom";
import { auctionsStateType } from "../../apis/types/common";
import { checkAuthenticationToken } from "../../utils/api";
import { convertAuctionsStateDataText } from "./handler";
import DefaultModal from "../DefaultModal";
import AuctionResultModalContext from "./AuctionResultModalContext";

export default function AuctionList({
  setPageState,
  setIsEditAuction,
  setCurrentAuctionId,
  setMemberId,
}: {
  setPageState: React.Dispatch<SetStateAction<AuctionPageStateType>>;
  setIsEditAuction: React.Dispatch<SetStateAction<boolean>>;
  setCurrentAuctionId: React.Dispatch<SetStateAction<number>>;
  setMemberId: React.Dispatch<SetStateAction<number>>;
}) {
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [selectedAuctionId, setSelectedAuctionId] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [auctionsData, setAuctionsData] = useState<resGetAuctionsType>({
    data: [],
    resultCode: 0,
    resultMessage: "",
    total: 0,
    totalPage: 0,
  });

  const getAuctionsApiFetch = async () => {
    const response = await instance.get(`/v1/auctions`, {
      params: {
        page: currentPage,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = response.data as resGetAuctionsType;
    if (result.resultCode === 200) {
      setAuctionsData(result);
      setTotalPage(result.totalPage);
    } else {
      checkAuthenticationToken(result, navigate, "/auction");
    }
  };

  /** 입장 전 memberId를 받기 위해 주최자 외에 참가자만 실행 */
  const postAuctionsAuctionIdEnter = async (auctionId: number) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.post(
      `/v1/auctions/${auctionId}/enter`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const result = response.data as resPostAuctionsAuctionIdEnterType;
    if (result.resultCode === 200) {
      // 현재 enter한 유저 memberId 저장
      setMemberId(result.data.auctionMemberId);
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const convertAuctionStateButton = (
    state: auctionsStateType,
    auctionId: number,
    hostUserId: number
  ) => {
    const userId = Number(window.localStorage.getItem("userId"));
    switch (state) {
      case auctionsStateType.BEFORE:
        return (
          <Button
            ml="48px"
            w="130px"
            onClick={() => {
              setIsEditAuction(true);
              setCurrentAuctionId(auctionId);
              setPageState("AUCTION_ROOM_SETTING");
            }}
          >
            수정
          </Button>
        );
      case auctionsStateType.CREATE:
        return (
          <Button
            ml="48px"
            w="130px"
            onClick={() => {
              if (hostUserId !== userId) postAuctionsAuctionIdEnter(auctionId);
              setCurrentAuctionId(auctionId);
              setPageState("AUCTION_START");
            }}
          >
            입장
          </Button>
        );
      case auctionsStateType.PROCESS:
        return (
          <Button
            ml="48px"
            w="130px"
            onClick={() => {
              if (hostUserId !== userId) postAuctionsAuctionIdEnter(auctionId);
              setCurrentAuctionId(auctionId);
              setPageState("AUCTION_START");
            }}
          >
            입장
          </Button>
        );
      default:
        return (
          <Button
            ml="48px"
            w="130px"
            onClick={() => {
              onOpen();
              setSelectedAuctionId(auctionId);
            }}
          >
            결과 확인
          </Button>
        );
    }
  };

  useEffect(() => {
    if (accessToken) getAuctionsApiFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, accessToken, refreshToken]);

  return (
    <Box display="flex" flexDir="column" w="100%" alignItems="flex-end">
      <Button
        w="180px"
        size="lg"
        onClick={() => {
          getAuctionsApiFetch();
          window.location.replace("/auction");
        }}
      >
        <Image src={RefreshIcon} w="16px" h="16px" />
        <Text pl="8px">리스트 새로고침</Text>
      </Button>
      <Box w="100%">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                {[
                  { text: "경매 이름", width: "320px" },
                  { text: "게임 이름", width: "220px" },
                  { text: "총 팀 수", width: "140px" },
                  { text: "팀당 인원 수", width: "140px" },
                  { text: "경매 상태", width: "260px" },
                ].flatMap((item) => (
                  <Th
                    p="12px 8px"
                    w={item.width}
                    key={item.text}
                    fontSize="large"
                  >
                    {item.text}
                  </Th>
                ))}
              </Tr>
            </Thead>
            {auctionsData.data.length > 0 ? (
              <>
                <Tbody>
                  {auctionsData.data.map((data) => (
                    <Tr key={data.auctionId}>
                      <Td h="52px" p="8px 4px" w="320px">
                        {data.title}
                      </Td>
                      <Td h="52px" p="8px 4px" w="220px">
                        {data.gameName}
                      </Td>
                      <Td h="52px" p="8px 4px" w="140px">
                        {data.teamCount + " 팀"}
                      </Td>
                      <Td h="52px" p="8px 4px" w="140px">
                        {data.memberCount + " 명"}
                      </Td>
                      <Td h="52px" p="8px 4px" w="260px">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {convertAuctionsStateDataText(data.state)}
                          {convertAuctionStateButton(
                            data.state,
                            data.auctionId,
                            data.hostUserId
                          )}
                        </Box>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Td colSpan={5} border={0}>
                      <Box
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        p="16px 0"
                      >
                        <Pagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          totalPage={totalPage}
                        />
                      </Box>
                    </Td>
                  </Tr>
                </Tfoot>
              </>
            ) : (
              <Tbody h="600px">
                <Tr
                  border={0}
                  textAlign="center"
                  fontSize="18px"
                  color="var(--chakra-colors-gray-500)"
                >
                  <Td colSpan={5} border={0}>
                    참여중인 경매가 없습니다.
                  </Td>
                </Tr>
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Box>
      <Button
        colorScheme="teal"
        size="lg"
        w="340px"
        onClick={() => setPageState("AUCTION_ROOM_SETTING")}
      >
        새로운 경매 생성
      </Button>
      <DefaultModal isOpen={isOpen} onClose={onClose} title="경매 결과">
        <AuctionResultModalContext auctionId={selectedAuctionId} />
      </DefaultModal>
    </Box>
  );
}
