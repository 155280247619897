import { Box, ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function AppWrapper({
  children,
}: {
  children?: React.ReactNode;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (
      accessToken &&
      (location.pathname === "/signin" || location.pathname === "signup")
    ) {
      return navigate("/");
    }
  }, [accessToken, location.pathname, navigate]);

  return (
    <ChakraProvider>
      <Box
        display="grid"
        gridTemplateColumns={children ? "1fr 6fr" : "1fr"}
        width="1640px"
        height="100%"
        minHeight="800px"
        margin="0 auto"
      >
        {children ? children : <Outlet />}
      </Box>
    </ChakraProvider>
  );
}
