import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { instance } from "../../apis/axios/instance";
import { reqPostLoginType, resPostLoginType } from "../../apis/types/login";
import { useNavigate } from "react-router-dom";
import { signInValidation, handleChangeInputState } from "./handler";

export default function SignIn() {
  const navigate = useNavigate();
  const [inputIdValue, setInputIdValue] = useState("");
  const [inputPwValue, setInputPwValue] = useState("");
  const [isError, setIsError] = useState<{ [key: string]: boolean }>({
    inputId: false,
    inputPw: false,
  });

  const postLoginApiFetch = async (req: reqPostLoginType) => {
    const response = await instance.post(`/v1/login`, req);
    const result = response.data as resPostLoginType;
    if (result.resultCode === 400) {
      alert(result.resultMessage);
    } else {
      const accessToken = response.headers["authorization"].split(" ")[1];
      const refreshToken = response.headers["refresh-token"];
      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem("refreshToken", refreshToken);
      window.localStorage.setItem("userId", String(result.data.userId));
      return navigate("/");
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        width="820px"
        p="120px"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        height="auto"
        textAlign="center"
      >
        <Heading pb="32px">로그인</Heading>
        <Box display="grid" gap="16px">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (signInValidation(inputIdValue, inputPwValue)) {
                postLoginApiFetch({
                  loginId: inputIdValue,
                  loginPassword: inputPwValue,
                });
              } else {
                alert("아이디, 비밀번호를 확인해주세요.");
              }
            }}
          >
            <FormControl
              isInvalid={isError.inputId}
              display="grid"
              alignItems="center"
              gridTemplateColumns="2fr 10fr"
              pb="24px"
              isRequired
            >
              <FormLabel alignItems="center">아이디</FormLabel>
              <Input
                type="text"
                size="lg"
                name="inputId"
                value={inputIdValue}
                onChange={(e) =>
                  handleChangeInputState(e, setInputIdValue, setIsError)
                }
              />
              {isError.inputId && (
                <FormErrorMessage pl="98px" gridColumn="1/3">
                  아이디를 입력해주세요.
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isInvalid={isError.inputPw}
              display="grid"
              alignItems="center"
              gridTemplateColumns="2fr 10fr"
              pb="24px"
              isRequired
            >
              <FormLabel>비밀번호</FormLabel>
              <Input
                type="password"
                size="lg"
                name="inputPw"
                value={inputPwValue}
                onChange={(e) =>
                  handleChangeInputState(e, setInputPwValue, setIsError)
                }
              />
              {isError.inputPw && (
                <FormErrorMessage pl="98px" gridColumn="1/3">
                  비밀번호를 입력해주세요.
                </FormErrorMessage>
              )}
            </FormControl>
            <Button type="submit" colorScheme="teal" size="lg" w="100%">
              로그인
            </Button>
          </form>
        </Box>
        <Box
          w="500px"
          m="0 auto"
          pt="24px"
          display="grid"
          gridTemplateColumns="1fr 0.2fr 1fr 0.2fr 1fr"
          gridColumnGap="12px"
        >
          <Link href="/signup">회원가입</Link>|
          <Link href="/findPassword">비밀번호 찾기</Link>|
          <Link
            target="_blank"
            href="https://blog.naver.com/dododosa/223487729987"
          >
            가이드
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
