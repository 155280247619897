import { Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { Box, Grid, GridItem, Heading } from "@chakra-ui/react";
import {
  AuctionChatRoom,
  AuctionTarget,
  AuctionTeamList,
  AuctionMemberList,
  WaitingMembers,
  AuctionBidTeamList,
} from "./StartAuctionContents";
import { useEffect, useState } from "react";
import { instance } from "../../apis/axios/instance";
import {
  resGetAuctionsAuctionIdMembersType,
  resGetAuctionsAuctionIdType,
} from "../../apis/types/auctions";
import {
  resWsBidTimeType,
  resWsEnterMemberType,
  resWsDoChatType,
  resWsEndAuction,
} from "../../apis/types/ws";
import { memberType, userTeamRoleType } from "../../apis/types/common";
import Toast from "../Toast";
import wsApi from "./handler/wsApi";
import EndAuctionResult from "./StartAuctionContents/EndAuctionResult";

export default function StartAuction({
  currentAuctionId,
  memberId,
}: {
  currentAuctionId: number;
  memberId: number;
}) {
  const userId = Number(window.localStorage.getItem("userId"));
  const [endAuctionState, setEndAuctionState] = useState<{
    isEnd: boolean;
    endAuctionData: resWsEndAuction | undefined;
  }>({
    isEnd: false,
    endAuctionData: undefined,
  });
  const [hostUserId, setHostUserId] = useState(0);
  const [stompClient, setStompClient] = useState<any>(null);
  const {
    sendAllChatMessage,
    sendBiddingMessage,
    sendCreateMessage,
    sendEnterMessage,
    sendStartMessage,
    sendTeamChatMessage,
  } = wsApi({
    client: stompClient,
    memberId,
    auctionId: currentAuctionId,
    userId,
  });
  const [bidTimeWsResponse, setBidTimeWsResponse] =
    useState<resWsBidTimeType>();
  const [doChatWsResponse, setDoChatWsResponse] = useState<string[]>([]);
  const [doTeamChatWsResponse, setDoTeamChatWsResponse] = useState<string[]>(
    []
  );
  const [isValidChatRoom, setIsValidChatRoom] = useState(false);
  const [enterMemberWsResponse, setEnterMemberWsResponse] =
    useState<resWsEnterMemberType>({
      auctionId: currentAuctionId,
      command: "enterMember",
      enterMemberId: 0,
      enterMemberCount: 0,
      enterMustMemberCount: 0,
      memberCount: 0,
      mustMemberCount: 0,
      enteredMembers: null,
      enteredLeaders: null,
      isEnterHost: false,
    });

  const [enteredMembers, setEnteredMembers] = useState<number[]>();
  const [auctionData, setAuctionData] = useState<resGetAuctionsAuctionIdType>();
  const [auctionMembers, setAuctionMembers] =
    useState<resGetAuctionsAuctionIdMembersType>();
  const [isWaitingMembers, setIsWaitingMembers] = useState(true);
  const [auctionTeamLeaders, setAuctionTeamLeaders] = useState<memberType[]>();
  const [toastState, setToastState] = useState<{
    isOpen: boolean;
    bidderName: string | undefined;
  }>({
    isOpen: false,
    bidderName: undefined,
  });

  /** 단일 경매 조회 */
  const getAuctionsAuctionIdApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.get(`/v1/auctions/${currentAuctionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = response.data as resGetAuctionsAuctionIdType;
    if (result.resultCode === 200) {
      setAuctionData(result);
      setHostUserId(result.data.hostUserId);
    }
  };

  /** 경매 참여자 리스트 */
  const getAuctionsAuctionIdMembersApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.get(
      `/v1/auctions/${currentAuctionId}/members`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const result = response.data as resGetAuctionsAuctionIdMembersType;
    if (result.resultCode === 200) {
      setAuctionMembers(result);
      setAuctionTeamLeaders(
        result.data.filter((user) => user.teamRole === userTeamRoleType.LEADER)
      );
    }
  };

  useEffect(() => {
    getAuctionsAuctionIdApiFetch();
    getAuctionsAuctionIdMembersApiFetch();
    const socket = new SockJS("http://teamDiv.api.doseh.co.kr/ws");
    const client = Stomp.over(socket);
    setStompClient(client);

    return () => {
      if (client) {
        client.disconnect(() => {});
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  useEffect(() => {
    if (stompClient) {
      stompClient.connect({}, (frame: any) => {
        // command : create
        sendCreateMessage();
        // command : enter
        sendEnterMessage(stompClient, memberId);

        // enter외 다른 사람들이 입장 후 받는 모든 웹소켓 구독
        stompClient.subscribe("/user/topic/auction", (response: any) => {
          const command = JSON.parse(response.body);
          switch (command.command) {
            case "bidTime":
            case "doStart":
              setIsWaitingMembers(false);
              setBidTimeWsResponse(command);
              break;

            case "enterMember":
              setEnteredMembers(command.enteredMembers);
              setEnterMemberWsResponse(command as resWsEnterMemberType);
              break;

            case "doChat": {
              const doChatData = command as resWsDoChatType;
              const speakingMember = doChatData.nickname;
              const enterMessage = doChatData.chat;
              setDoChatWsResponse((prev) => [
                `${speakingMember} : ${enterMessage}`,
                ...prev,
              ]);
              break;
            }

            case "doTeamChat": {
              const doChatData = command as resWsDoChatType;
              const speakingMember = doChatData.nickname;
              const enterMessage = doChatData.chat;
              setDoTeamChatWsResponse((prev) => [
                `${speakingMember} : ${enterMessage}`,
                ...prev,
              ]);
              break;
            }

            case "endAuction": {
              const endAuctionData = command as resWsEndAuction;
              setEndAuctionState({
                isEnd: true,
                endAuctionData: endAuctionData,
              });
              break;
            }
            default:
              if (
                command.memberState === "REJECT" ||
                command.memberState === "COMPLETE"
              ) {
                const { bidder } = command as resWsBidTimeType;
                const bidderName = auctionTeamLeaders?.filter(
                  (item) => item.auctionMemberId === bidder
                )[0]?.nickname;
                setToastState({
                  isOpen: true,
                  bidderName,
                });
                // 스르륵 2초 후 토스트 없애기
                setTimeout(() => {
                  setToastState({
                    isOpen: false,
                    bidderName,
                  });
                }, 1200);
                setBidTimeWsResponse(command);
              }
              break;
          }
        });
        // enter시 1대1 반환 값을 받기위한 웹소켓 구독
        stompClient.subscribe("/user/queue/reply", () => {});
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionTeamLeaders, memberId, stompClient]);

  useEffect(() => {
    if (bidTimeWsResponse) {
      // 팀원 유효성 체크
      const isMemberInTeams =
        bidTimeWsResponse.teams?.some((member) =>
          member.memberIds.includes(memberId as never)
        ) ?? false;
      // 팀장 유효성 체크
      const isLeaderInTeams =
        bidTimeWsResponse.teams?.some(
          (member) => member.leaderId === memberId
        ) ?? false;

      setIsValidChatRoom(isMemberInTeams || isLeaderInTeams);
    } else {
      if (auctionTeamLeaders) {
        // setIsValidChatRoom()
        setIsValidChatRoom(
          auctionTeamLeaders.some(
            (leader) => leader.auctionMemberId === memberId
          )
        );
      }
    }
  }, [bidTimeWsResponse, memberId, auctionTeamLeaders]);

  const handleTargetBoxComponents = () => {
    if (auctionData && !isWaitingMembers)
      return (
        <AuctionTarget
          memberId={memberId}
          bidTimeWsResponse={bidTimeWsResponse}
          auctionMembers={auctionMembers}
          sendBiddingMessage={sendBiddingMessage}
          teamLeadersIds={
            bidTimeWsResponse && bidTimeWsResponse.teams
              ? bidTimeWsResponse.teams.map((item) => item.leaderId)
              : [0]
          }
          auctionTeamLeaders={auctionTeamLeaders}
          auctionCount={auctionData.data.memberCount}
        />
      );
    if (auctionData && isWaitingMembers)
      return (
        <WaitingMembers
          setIsWaitingMembers={setIsWaitingMembers}
          enterMemberWsResponse={enterMemberWsResponse}
          hostUserId={hostUserId}
          sendStartMessage={sendStartMessage}
        />
      );
  };

  if (!auctionData) return <></>;

  return (
    <>
      {endAuctionState.isEnd ? (
        <EndAuctionResult
          auctionMembers={auctionMembers}
          endAuctionData={endAuctionState.endAuctionData}
          auctionTitle={auctionData?.data.title}
          auctionTeamLeaders={auctionTeamLeaders}
        />
      ) : (
        <Box
          w="100%"
          display="flex"
          flexDirection="column"
          gap="12px"
          position="relative"
        >
          <Box display="flex" justifyContent="space-between">
            <Heading size="lg" color="teal">
              {auctionData.data.title}
            </Heading>
          </Box>
          <Grid
            gridTemplateColumns="4fr 1.4fr"
            gridTemplateRows="720px"
            gap="8px"
          >
            <Grid gap="8px" gridTemplateRows="5fr 2fr">
              <Grid gridTemplateColumns="1.4fr 1fr" h="100%" gap="8px">
                <GridItem>
                  {/* 경매 중인 팀 리스트 */}
                  {isWaitingMembers ? (
                    <AuctionTeamList
                      auctionMembers={auctionMembers}
                      memberId={memberId}
                      auctionTeamLeaders={auctionTeamLeaders}
                    />
                  ) : (
                    <AuctionBidTeamList
                      auctionMembers={auctionMembers}
                      bidTeamsData={bidTimeWsResponse?.teams}
                      memberId={memberId}
                      auctionTeamLeaders={auctionTeamLeaders}
                    />
                  )}
                </GridItem>
                <GridItem>
                  {/* 경매 대상 */}
                  {handleTargetBoxComponents()}
                </GridItem>
              </Grid>
              <Grid gridTemplateColumns="1fr 1fr" gap="16px">
                {/* 하단 채팅 방 */}
                <GridItem>
                  <AuctionChatRoom
                    title="팀"
                    chatData={doTeamChatWsResponse}
                    sendMessage={sendTeamChatMessage}
                    isValidChatRoom={isValidChatRoom}
                  />
                </GridItem>
                <GridItem>
                  <AuctionChatRoom
                    title="전체"
                    chatData={doChatWsResponse}
                    sendMessage={sendAllChatMessage}
                    isValidChatRoom={true}
                  />
                </GridItem>
              </Grid>
            </Grid>
            {/* 우측 경매 대기 리스트 */}
            <Box display="flex" flexDir="column" justifyContent="space-between">
              {auctionMembers ? (
                <AuctionMemberList
                  auctionMembers={auctionMembers.data.filter(
                    (item) => item.teamRole === userTeamRoleType.MEMBER
                  )}
                  enteredMembers={enteredMembers}
                  bidTimeEntry={bidTimeWsResponse?.entry}
                />
              ) : null}
            </Box>
            <Toast
              isToastOpen={toastState.isOpen}
              toastText={
                toastState.bidderName
                  ? `${toastState.bidderName}팀이 입찰하였습니다.`
                  : "유찰됐습니다 유감"
              }
            />
          </Grid>
        </Box>
      )}
    </>
  );
}
