import { CloseIcon } from "@chakra-ui/icons";
import {
  Tr,
  Td,
  Badge,
  HStack,
  Button,
  Box,
  Image,
  Text,
} from "@chakra-ui/react";
import {
  reqPatchAuctionsAuctionIdMembersMemberIdType,
  resGetAuctionsAuctionIdMembersType,
} from "../../../apis/types/auctions";
import UserIcon from "../../../icon/default-pepe-icon.png";
import { IMAGE_BASE_URL } from "../../../constants/url";
import {
  apiResponseCommonType,
  cyphersTierType,
  cyphersTierTypeOrder,
  userTeamRoleType,
} from "../../../apis/types/common";
import { instance } from "../../../apis/axios/instance";
import { useNavigate } from "react-router-dom";
import { checkAuthenticationToken } from "../../../utils/api";

export default function AcutionMemberList({
  auctionMinTier,
  auctionMaxTier,
  currentAuctionId,
  memberList,
  memberListApiFetch,
}: {
  auctionMinTier: cyphersTierType;
  auctionMaxTier: cyphersTierType;
  currentAuctionId: number;
  memberList: resGetAuctionsAuctionIdMembersType;
  memberListApiFetch: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const patchAuctionsAuctionIdMembersMemberIdApiFetch = async (
    req: reqPatchAuctionsAuctionIdMembersMemberIdType,
    memberId: number
  ) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.patch(
      `/v1/auctions/${currentAuctionId}/members/${memberId}`,
      req,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 200) {
      memberListApiFetch();
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const deleteAuctionsAuctionIdMembersMemberIdApiFetch = async (
    memberId: number
  ) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.delete(
      `/v1/auctions/${currentAuctionId}/members/${memberId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 200) {
      memberListApiFetch();
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const convertInvalidateMemberTier = (tier: cyphersTierType) => {
    const minTier = auctionMinTier;
    const maxTier =
      auctionMaxTier === cyphersTierType.없음
        ? cyphersTierType.에이스
        : auctionMaxTier;
    if (
      cyphersTierTypeOrder.indexOf(minTier) <=
        cyphersTierTypeOrder.indexOf(tier) &&
      cyphersTierTypeOrder.indexOf(tier) <=
        cyphersTierTypeOrder.indexOf(maxTier)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {memberList.data.map((item) => {
        const profileIconUrl = item.profileIconURL
          ? IMAGE_BASE_URL + item.profileIconURL
          : UserIcon;
        return (
          <Tr h="52px" key={item.auctionMemberId}>
            <Td
              p="8px 4px"
              w="30%"
              display="flex"
              alignItems="center"
              gap="24px"
            >
              <Image src={profileIconUrl} alt="user icon" w="48px" h="48px" />
              {item.nickname}
            </Td>
            <Td p="8px 4px" w="25%">
              <Badge p="4px 8px" fontSize="20px">
                {item.profileResponse.presentTier}
              </Badge>
              {convertInvalidateMemberTier(
                item.profileResponse.presentTier
              ) ? null : (
                <Text as="span" color="red" ml="8px" fontSize="14px">
                  (잘못된 범위)
                </Text>
              )}
            </Td>
            <Td p="8px 4px" w="15%">
              {item.profileResponse.mainPosition}
            </Td>
            <Td p="8px 4px" w="30%">
              <Box
                display="flex"
                flexDir="row"
                gap="16px"
                justifyContent="space-between"
                alignItems="center"
              >
                <HStack>
                  <Text
                    w="100px"
                    visibility={
                      item.teamRole === userTeamRoleType.LEADER
                        ? "visible"
                        : "hidden"
                    }
                    color="var(--chakra-colors-red-600)"
                    fontWeight="700"
                  >
                    팀장
                  </Text>
                  {item.teamRole === userTeamRoleType.LEADER ? (
                    <Button
                      variant="outline"
                      onClick={() =>
                        patchAuctionsAuctionIdMembersMemberIdApiFetch(
                          {
                            teamRole: userTeamRoleType.MEMBER,
                          },
                          item.auctionMemberId
                        )
                      }
                    >
                      팀장 해제
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      onClick={() =>
                        patchAuctionsAuctionIdMembersMemberIdApiFetch(
                          {
                            teamRole: userTeamRoleType.LEADER,
                          },
                          item.auctionMemberId
                        )
                      }
                    >
                      팀장 설정
                    </Button>
                  )}
                </HStack>
                <Button
                  leftIcon={<CloseIcon w="10px" h="10px" />}
                  colorScheme="red"
                  variant="outline"
                  onClick={() =>
                    deleteAuctionsAuctionIdMembersMemberIdApiFetch(
                      item.auctionMemberId
                    )
                  }
                >
                  제외
                </Button>
              </Box>
            </Td>
          </Tr>
        );
      })}
    </>
  );
}
