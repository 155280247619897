import { auctionsStateType } from "../../../apis/types/common";

export const convertAuctionsStateDataText = (name: string) => {
  switch (name) {
    case auctionsStateType.BEFORE:
      return "시작 전";
    case auctionsStateType.CREATE:
      return "입장 시작";
    case auctionsStateType.PROCESS:
      return "진행 중";
    default:
      return "완료";
  }
};
