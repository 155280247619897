import {
  Text,
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Badge,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Grid,
  Divider,
  FormErrorMessage,
  Td,
} from "@chakra-ui/react";
import { SetStateAction, useEffect, useState } from "react";
import { AuctionPageStateType } from "../../pages/auction/Auction";
import { instance } from "../../apis/axios/instance";
import {
  apiResponseCommonType,
  cyphersTierType,
  cyphersTierTypeOrder,
  userTeamRoleType,
} from "../../apis/types/common";
import {
  reqPostAuctionsAuctionIdMembersType,
  resGetAuctionsAuctionIdMembersType,
  resGetAuctionsAuctionIdType,
  resPostAuctionsAuctionIdMembersType,
} from "../../apis/types/auctions";
import { useNavigate } from "react-router-dom";
import AcutionMemberList from "./AuctionUserContents/AcutionMemberList";
import { checkAuthenticationToken } from "../../utils/api";

export const auctionCyphersTierType = {
  ...cyphersTierType,
  NONE: "NONE",
};

export default function AuctionUser({
  currentAuctionId,
  setPageState,
  setIsEditAuction,
}: {
  currentAuctionId: number;
  setPageState: React.Dispatch<SetStateAction<AuctionPageStateType>>;
  setIsEditAuction: React.Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const [auctionData, setAuctionData] = useState<resGetAuctionsAuctionIdType>();
  const [auctionMemberList, setAuctionMemberList] =
    useState<resGetAuctionsAuctionIdMembersType>();
  const [nickname, setNickname] = useState("");
  const [allMemberCount, setAllMemberCount] = useState(0);
  const [errorState, setErrorState] = useState({
    isError: false,
    resultMsg: "",
  });

  const convertMembersCountText = () => {
    const participantsCount = auctionMemberList?.data.length || 0;
    const filteredTeamReaderCount =
      auctionMemberList?.data.filter(
        (item) => item.teamRole === userTeamRoleType.LEADER
      ).length || 0;
    const teamsCount = auctionData?.data.teamCount || 0;
    return (
      <Box display="flex">
        <Text>참여자: </Text>
        <Text
          style={{
            color:
              participantsCount > allMemberCount
                ? "var(--chakra-colors-red-500)"
                : "",
          }}
        >
          {participantsCount} / {allMemberCount}
        </Text>
        <Text>, 팀장: </Text>
        <Text
          style={{
            color:
              filteredTeamReaderCount > teamsCount
                ? "var(--chakra-colors-red-500)"
                : "",
          }}
        >
          {filteredTeamReaderCount} / {teamsCount}
        </Text>
      </Box>
    );
  };

  const postAuctionsAuctionIdCreateApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.post(
      `/v1/auctions/${currentAuctionId}/create`,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 200) {
      setPageState("AUCTION_START");
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const getAuctionsAuctionIdApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.get(`/v1/auctions/${currentAuctionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = response.data as resGetAuctionsAuctionIdType;
    if (result.resultCode === 200) {
      const { memberCount, teamCount } = result.data;
      setAuctionData(result);
      setAllMemberCount(memberCount * teamCount);
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const getAuctionsAuctionIdMembersApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.get(
      `/v1/auctions/${currentAuctionId}/members`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const result = response.data as resGetAuctionsAuctionIdMembersType;
    if (result.resultCode === 200) {
      setAuctionMemberList(result);
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const postAuctionsAuctionIdMembersApiFetch = async (
    req: reqPostAuctionsAuctionIdMembersType
  ) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.post(
      `/v1/auctions/${currentAuctionId}/members`,
      req,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const result = response.data as resPostAuctionsAuctionIdMembersType;
    if (result.resultCode === 200) {
      getAuctionsAuctionIdMembersApiFetch();
    } else {
      setErrorState({
        isError: true,
        resultMsg: result.resultMessage,
      });
    }
  };

  useEffect(() => {
    getAuctionsAuctionIdApiFetch();
    getAuctionsAuctionIdMembersApiFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAuctionId]);

  return (
    <Box w="100%" display="flex" flexDirection="column" gap="24px">
      <Box w="100%" display="flex" gap="24px">
        <Heading size="lg" color="teal">
          {auctionData?.data.title}
        </Heading>
        <Text>
          <Badge p="0px 8px" m="0 8px" fontSize="20px" colorScheme="green">
            {auctionData?.data.minTier || cyphersTierTypeOrder[5]}
          </Badge>
          ~
          <Badge p="0px 8px" m="0 8px" fontSize="20px" colorScheme="red">
            {auctionData?.data.maxTier || cyphersTierTypeOrder[5]}
          </Badge>
        </Text>
      </Box>
      <TableContainer>
        <Box w="100%" pb="24px">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (nickname !== "") {
                postAuctionsAuctionIdMembersApiFetch({
                  nickname,
                });
              } else {
                alert("닉네임을 입력해주세요.");
              }
            }}
          >
            <FormControl
              isInvalid={errorState.isError}
              display="grid"
              gap="16px"
              alignItems="center"
              gridTemplateColumns="1fr 3fr 2fr"
              pl="560px"
            >
              <FormLabel alignItems="center" mb="0" fontSize="20px">
                닉네임
              </FormLabel>
              <Input
                size="lg"
                type="text"
                value={nickname}
                onChange={(e) => {
                  setErrorState({
                    isError: false,
                    resultMsg: "",
                  });
                  setNickname(e.target.value);
                }}
              />
              <Button size="lg" type="submit">
                참여자 추가
              </Button>
              {errorState.isError && (
                <FormErrorMessage pl="140px" gridColumn="1/3">
                  {errorState.resultMsg}
                </FormErrorMessage>
              )}
            </FormControl>
          </form>
        </Box>
        <Divider />
        <Table>
          <Thead>
            <Tr>
              {[
                { text: "닉네임", width: "30%" },
                { text: "현재 티어", width: "24%" },
                { text: "주 포지션", width: "16%" },
                { text: "", width: "30%" },
              ].flatMap((item) => (
                <Th
                  p="12px 8px"
                  w={item.width}
                  key={item.text}
                  fontSize="large"
                >
                  {item.text}
                </Th>
              ))}
            </Tr>
          </Thead>
        </Table>
        <Box h="480px" overflowY="auto">
          <Table>
            <Tbody>
              {auctionMemberList && auctionMemberList.data.length > 0 ? (
                <AcutionMemberList
                  auctionMinTier={
                    auctionData?.data.minTier || cyphersTierType.없음
                  }
                  auctionMaxTier={
                    auctionData?.data.maxTier || cyphersTierType.없음
                  }
                  currentAuctionId={currentAuctionId}
                  memberList={auctionMemberList}
                  memberListApiFetch={getAuctionsAuctionIdMembersApiFetch}
                />
              ) : (
                <Tr color="var(--chakra-colors-gray-500)">
                  <Td>
                    <Text
                      h="480px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      fontSize="18px"
                    >
                      닉네임으로 참가자를 추가해 주세요.
                    </Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
        <Box w="100%" display="flex" justifyContent="flex-end" p="12px 0">
          {convertMembersCountText()}
        </Box>
      </TableContainer>
      <Grid gap="16px" gridTemplateColumns="1fr 1fr">
        <Button
          size="lg"
          onClick={() => {
            setIsEditAuction(true);
            setPageState("AUCTION_ROOM_SETTING");
          }}
        >
          경매 설정으로 돌아가기
        </Button>
        <Button
          size="lg"
          colorScheme="teal"
          onClick={() => {
            postAuctionsAuctionIdCreateApiFetch();
          }}
        >
          입장 시작
        </Button>
      </Grid>
    </Box>
  );
}
