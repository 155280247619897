/** 게임 종류 */
export enum gameType {
  사이퍼즈 = "사이퍼즈",
  LoL = "LoL",
  오버워치 = "오버워치",
}

/** 사이퍼즈 티어 */
export enum cyphersTierType {
  없음 = "없음",
  브론즈 = "브론즈",
  실버 = "실버",
  골드 = "골드",
  조커 = "조커",
  에이스 = "에이스",
}

export const cyphersTierTypeOrder = [
  cyphersTierType.없음,
  cyphersTierType.브론즈,
  cyphersTierType.실버,
  cyphersTierType.골드,
  cyphersTierType.조커,
  cyphersTierType.에이스,
];

/** 사이퍼즈 모든 포지션 */
export enum cyphersPositionType {
  올라운더 = "올라운더",
  탱커 = "탱커",
  근딜 = "근딜",
  원딜 = "원딜",
  서폿 = "서폿",
}

/** 경매 리스트 상태 */
export enum auctionsStateType {
  /** 시작 전 */
  BEFORE = "BEFORE",
  /** 입장 시작 */
  CREATE = "CREATE",
  /** 진행 중 */
  PROCESS = "PROCESS",
  /** 완료 */
  DONE = "DONE",
}

/** 경매 참가자의 상태 */
export enum auctionMemberStateType {
  /** 초대 */
  INVITE = "INVITE",
  /** 경매방 입장 */
  ENTER = "ENTER",
  /** 경매중 */
  PROCESS = "PROCESS",
  /** 낙찰 */
  COMPLETE = "COMPLETE",
  /** 유찰 */
  REJECT = "REJECT",
}

export type userProfilesType = {
  /** 게임 종류 */
  gameName: gameType;
  /** 현재 티어 */
  presentTier: cyphersTierType;
  /** 최고 티어 */
  highestTier: cyphersTierType;
  /** 주 포지션 */
  mainPosition: cyphersPositionType;
  /** 부 포지션 */
  subPosition: cyphersPositionType;
  /** 인게임 닉네임 */
  inGameNickname: string;
  /** 주 캐릭터 */
  mainCharacter: string;
  /** 자기 소개 */
  selfIntro: string;
};

export enum userTeamRoleType {
  /** 팀원 */
  MEMBER = "MEMBER",
  /** 팀장 */
  LEADER = "LEADER",
}

export type memberType = {
  /** 경매 맴버 id */
  auctionMemberId: number;
  /** 프로필 아이콘 url */
  profileIconURL: string | null;
  /** 경매 맴버 닉네임 */
  nickname: string;
  /** 팀원 룰  */
  teamRole: userTeamRoleType;
  /** 경매 순서 */
  auctionOrder: number;
  /** 입찰된 순서 */
  memberOrder: number | null;
  /** 입찰 가격 */
  biddingPrice: number | null;
  /** 경매 멤버의 경매 상태 */
  state: auctionMemberStateType;
  /** 경매 id */
  auctionId: number;
  /** 경매 이름 */
  auctionTitle: string;
  /** 회원 id */
  userId: number;
  /** 경매 팀 id  */
  auctionTeamId: number | null;
  /** 맴버 프로필 내용 */
  profileResponse: userProfilesType;
};

export type auctionsType = {
  /** 경매 id */
  auctionId: number;
  /** 경매 제목 */
  title: string;
  /** 경매 게임 종류 */
  gameName: gameType;
  /** 경매 총 팀 수 */
  teamCount: number;
  /** 경매 팀당 인원 수 */
  memberCount: number;
  /** 경매 최초 포인트 */
  biddingPoint: number;
  /** 경매 참가 최소 티어 */
  minTier: cyphersTierType;
  /** 경매 참가 최대 티어 */
  maxTier: cyphersTierType;
  /** 경매 진행 상태 */
  state: auctionsStateType;
  /** 주최자 회원 id */
  hostUserId: number;
};

export type apiResponseCommonType<T> = {
  data?: T;
  resultCode: number;
  resultMessage: string;
};
