// import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Image,
  // Link,
  Divider,
  Card,
  CardBody,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const SuccessSignUp = ({ passwordKey }: { passwordKey: string }) => {
  const navigate = useNavigate();

  // TODO : https로 전환 후 사용
  // const onClickClipboardShareLink = async (copyText: string) => {
  //   await navigator.clipboard.writeText(copyText);
  //   alert("클립보드에 복사되었습니다.");
  // };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        width="960px"
        p="120px"
        display="grid"
        gap="24px"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        height="auto"
        textAlign="center"
      >
        <Image
          src="/teamdiv_logo.png"
          alt="마을주민1팀나눠 로고.png"
          margin="0 auto"
        />
        <Heading>회원가입 성공</Heading>
        <Divider />
        <Box>
          마을주민1팀나눠에 오신 것을 환영합니다!
          <br />
          아래의 키 값은 이후 가입하신 아이디의 ‘비밀번호 찾기’에 필요합니다.
          따로 저장해서 사용해주세요.
          <br />
          재발급은 불가능합니다.
        </Box>
        <Card variant="filled" textAlign="left">
          <CardBody display="grid" gap="12px">
            <Heading size="m" textTransform="uppercase">
              키 값
            </Heading>
            {passwordKey}
            {/* <Link
              fontSize="large"
              onClick={() => onClickClipboardShareLink(passwordKey)}
              cursor="pointer"
            >
              {passwordKey}
              <CopyIcon ml="8px" />
            </Link> */}
          </CardBody>
        </Card>
        <Button
          onClick={() => navigate("/signin")}
          colorScheme="teal"
          size="lg"
          w="100%"
        >
          로그인하러 가기
        </Button>
      </Box>
    </Box>
  );
};
