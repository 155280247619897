import {
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { SuccessFindPassword } from "../../components/Authorization/SuccessFindPassword";
import { instance } from "../../apis/axios/instance";
import { reqGetUserPasswordAuthenticationType } from "../../apis/types/user";
import { checkAuthenticationToken } from "../../utils/api";
import { useNavigate } from "react-router-dom";

export default function FindPassword() {
  const navigate = useNavigate();
  const [isSuccessFindPwd, setIsSuccessFindPwd] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [passwordKey, setPasswordKey] = useState("");

  const handleChangeInputState = (
    e: React.ChangeEvent<HTMLInputElement>,
    setInputValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const onFindPasswordDataSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loginId === "") return alert("아이디를 입력해주세요.");
    if (passwordKey === "") return alert("비밀번호 키 값을 입력해주세요.");
    getUserPasswordAuthenticationApiFetch({
      loginId,
      passwordKey,
    });
  };

  const getUserPasswordAuthenticationApiFetch = async (
    req: reqGetUserPasswordAuthenticationType
  ) => {
    const response = await instance.get(`/v1/user/password-authentication`, {
      params: req,
    });
    const result = response.data;
    if (result.resultCode === 200) {
      setIsSuccessFindPwd(true);
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        width="820px"
        p="120px"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        height="auto"
        textAlign="center"
      >
        {!isSuccessFindPwd ? (
          <>
            <Heading pb="32px">키 값으로 비밀번호 찾기</Heading>
            <Box display="grid" gap="16px">
              <form onSubmit={(e) => onFindPasswordDataSubmit(e)}>
                <FormControl
                  display="grid"
                  alignItems="center"
                  gridTemplateColumns="2fr 10fr"
                  pb="24px"
                >
                  <FormLabel alignItems="center">아이디</FormLabel>
                  <Input
                    type="text"
                    size="lg"
                    value={loginId}
                    onChange={(e) => handleChangeInputState(e, setLoginId)}
                  />
                </FormControl>
                <FormControl
                  display="grid"
                  alignItems="center"
                  gridTemplateColumns="2fr 10fr"
                  pb="24px"
                >
                  <FormLabel>키 값</FormLabel>
                  <Input
                    type="text"
                    size="lg"
                    value={passwordKey}
                    onChange={(e) => handleChangeInputState(e, setPasswordKey)}
                  />
                </FormControl>
                <Card variant="filled" textAlign="left" mb="24px">
                  <CardBody>
                    <Text fontSize="16px">
                      * 키 값은 회원가입 시 주어지고, 다시 확인할 수 없습니다.
                    </Text>
                  </CardBody>
                </Card>
                <Button type="submit" colorScheme="teal" size="lg" w="100%">
                  비밀번호 찾기
                </Button>
              </form>
            </Box>
            <Box
              w="300px"
              m="0 auto"
              pt="24px"
              display="grid"
              gridTemplateColumns="1fr 0.2fr 1fr"
              gridColumnGap="12px"
            >
              <Link href="/signin">로그인</Link>|
              <Link href="/signup">회원가입</Link>
            </Box>
          </>
        ) : (
          <SuccessFindPassword passwordKey={passwordKey} />
        )}
      </Box>
    </Box>
  );
}
