import {
  Badge,
  Box,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { wsBidTimeTeamsType } from "../../../apis/types/ws";
import { useEffect, useState } from "react";
import { resGetAuctionsAuctionIdMembersType } from "../../../apis/types/auctions";
import { memberType } from "../../../apis/types/common";
import { IMAGE_BASE_URL } from "../../../constants/url";
import UserIcon from "../../../icon/default-pepe-icon.png";

const AuctionBidTeamList = ({
  auctionMembers,
  bidTeamsData,
  memberId,
  auctionTeamLeaders,
}: {
  auctionMembers: resGetAuctionsAuctionIdMembersType | undefined;
  bidTeamsData: wsBidTimeTeamsType[] | undefined;
  memberId: number;
  auctionTeamLeaders: memberType[] | undefined;
}) => {
  const [teamsData, setTeamsData] = useState<wsBidTimeTeamsType[]>();

  useEffect(() => {
    const isMyTeamValid = bidTeamsData?.filter((data) =>
      data.memberIds.includes(memberId as never)
    );
    if (isMyTeamValid) {
      const myTeamData: typeof bidTeamsData = [];
      const notMyTeamData: typeof bidTeamsData = [];

      bidTeamsData?.forEach((data) => {
        if (
          data.memberIds.includes(memberId as never) ||
          data.leaderId === memberId
        ) {
          myTeamData.push(data);
        } else {
          notMyTeamData.push(data);
        }
      });

      setTeamsData([...myTeamData, ...notMyTeamData]);
    }
  }, [bidTeamsData, memberId, setTeamsData]);

  const makeSkeletonUi = () => {
    return (
      <Stack h="500px" gap="8px">
        <Skeleton height="122px" borderRadius="16px" opacity="0.08" />
        <Skeleton height="122px" borderRadius="16px" opacity="0.08" />
      </Stack>
    );
  };

  if (!teamsData || !auctionMembers || !auctionTeamLeaders)
    return makeSkeletonUi();

  const presentTierColor = (presentTier: string) => {
    switch (presentTier) {
      case "에이스":
        return "var(--chakra-colors-red-400)";
      case "조커":
        return "var(--chakra-colors-purple-500)";
      case "골드":
        return "var(--chakra-colors-yellow-400)";
      case "실버":
        return "var(--chakra-colors-gray-400)";
      default:
        return "var(--chakra-colors-yellow-800)";
    }
  };

  return (
    <Box display="flex" h="500px" flexDir="column" gap="8px" overflow="auto">
      {teamsData.flatMap((data) => {
        const { leaderId, memberIds, remainingPoint } = data;
        const leaderUserData = auctionTeamLeaders.filter(
          (user) => user.auctionMemberId === data.leaderId
        )[0];
        const { nickname: leaderNickname, profileIconURL, profileResponse } = leaderUserData;
        const isMyTeam =
          leaderId === memberId || memberIds.includes(memberId as never);
        const leaderUserIcon = profileIconURL
          ? IMAGE_BASE_URL + profileIconURL
          : UserIcon;

        const tierColor = presentTierColor(profileResponse.presentTier);
        const border = "1px solid " + tierColor;

        return (
          <Box
            key={leaderId}
            borderWidth="1px"
            borderStyle="solid"
            borderColor={
              isMyTeam
                ? "var(--chakra-colors-teal-500)"
                : "var(--chakra-colors-gray-300)"
            }
            borderRadius="16px"
            boxSizing="border-box"
            p="12px"
          >
            <Box
              display="flex"
              alignItems="center"
              flexDir="row"
              justifyContent="space-between"
              pb="4px"
            >
              <Heading size="sm" display="flex">
                {leaderNickname + "팀"}
                {isMyTeam ? (
                  <Badge
                    borderRadius="8px"
                    ml="8px"
                    colorScheme="red"
                    lineHeight="1rem"
                  >
                    MY
                  </Badge>
                ) : null}
              </Heading>
              <Box display="flex" gap="8px" alignItems="center">
                <Text
                  fontSize="16px"
                  display="flex"
                  justifyContent="center"
                  gap="8px"
                >
                  잔여 :{" "}
                  <Badge colorScheme="teal" fontSize="14px">
                    {remainingPoint} P
                  </Badge>
                </Text>
              </Box>
            </Box>
            <Box display="flex" flexDir="row" gap="16px">
              <Box
                w="68px"
                h="68px"
                borderTop={border}
                borderLeft={border}
                borderRight={border}
                display="flex"
                flexDir="column"
                justifyContent="left"
                position="relative"
              >
                <Image
                  src={leaderUserIcon}
                  alt="팀장 아이콘"
                  w="68px"
                  h="68px"
                />
                <Box
                  bottom="0"
                  h="20px"
                  w="68px"
                  bgColor={tierColor}                  
                  position="absolute"
                >
                  <Text
                    color="var(--chakra-colors-white)"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontSize="12px"
                  >
                    {leaderNickname}
                  </Text>
                </Box>
              </Box>
              {/* 추가되는 멤버들 */}
              <>
                {memberIds.flatMap((memberId) => {
                  const memberUserData = auctionMembers.data.filter(
                    (item) => item.auctionMemberId === memberId
                  )[0];
                  const { profileIconURL, nickname, profileResponse } = memberUserData;

                  const tierColor = presentTierColor(profileResponse.presentTier);
                  const border = "2px solid " + tierColor;

                  return (
                    <Box
                      key={memberId}
                      w="68px"
                      display="flex"
                      flexDir="column"
                      justifyContent="left"
                      position="relative"
                      borderTop={border}
                      borderLeft={border}
                      borderRight={border}
                    >
                      <Image
                        src={
                          profileIconURL
                            ? IMAGE_BASE_URL + profileIconURL
                            : UserIcon
                        }
                        alt=""
                        w="68px"
                        h="68px"
                      />
                      <Box
                        bottom="0"
                        h="20px"
                        w="68px"
                        bgColor={tierColor}
                        position="absolute"
                      >
                        <Text
                          color="var(--chakra-colors-white)"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          fontSize="12px"
                        >
                          {nickname}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export { AuctionBidTeamList };
