import { Box } from "@chakra-ui/react";
import SettingAuction from "../../components/Auction/SettingAuction";
import AuctionList from "../../components/Auction/AuctionList";
import { useState } from "react";
import AuctionUser from "../../components/Auction/AuctionUser";
import StartAuction from "../../components/Auction/StartAuction";

export type AuctionPageStateType =
  | "AUCTION_LIST"
  | "AUCTION_ROOM_SETTING"
  | "AUCTION_USER_SETTING"
  | "AUCTION_START";

export default function Auction() {
  const [currentAuctionId, setCurrentAuctionId] = useState(0);
  const [memberId, setMemberId] = useState(0);
  const [pageState, setPageState] =
    useState<AuctionPageStateType>("AUCTION_LIST");
  const [isEditAuction, setIsEditAuction] = useState(false);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" gap="24px">
      {/* 경매 리스트 */}
      {pageState === "AUCTION_LIST" && (
        <AuctionList
          setPageState={setPageState}
          setIsEditAuction={setIsEditAuction}
          setCurrentAuctionId={setCurrentAuctionId}
          setMemberId={setMemberId}
        />
      )}
      {/* 경매 생성 & 수정 */}
      {pageState === "AUCTION_ROOM_SETTING" && (
        <SettingAuction
          currentAuctionId={currentAuctionId}
          setCurrentAuctionId={setCurrentAuctionId}
          setPageState={setPageState}
          isEditAuction={isEditAuction}
        />
      )}
      {/* 경매 유저 설정 */}
      {pageState === "AUCTION_USER_SETTING" && (
        <AuctionUser
          currentAuctionId={currentAuctionId}
          setPageState={setPageState}
          setIsEditAuction={setIsEditAuction}
        />
      )}
      {/* 경매 화면 */}
      {pageState === "AUCTION_START" && (
        <StartAuction currentAuctionId={currentAuctionId} memberId={memberId} />
      )}
    </Box>
  );
}
