import { resGetAuctionsAuctionIdMembersType } from "../../../apis/types/auctions";
import { resWsEndAuction } from "../../../apis/types/ws";
import { Heading, Box, Image, Text, HStack, Button } from "@chakra-ui/react";
import { IMAGE_BASE_URL } from "../../../constants/url";
import UserIcon from "../../../icon/default-pepe-icon.png";
import { memberType } from "../../../apis/types/common";

export default function EndAuctionResult({
  auctionMembers,
  endAuctionData,
  auctionTitle,
  auctionTeamLeaders,
}: {
  auctionMembers: resGetAuctionsAuctionIdMembersType | undefined;
  endAuctionData: resWsEndAuction | undefined;
  auctionTitle: string | undefined;
  auctionTeamLeaders: memberType[] | undefined;
}) {
  if (!endAuctionData) return <></>;
  if (!auctionMembers) return <></>;
  if (!auctionTeamLeaders) return <></>;

  return (
    <Box
      display="flex"
      flexDir="column"
      w="100%"
      gap="24px"
      justifyContent="space-between"
    >
      <Heading size="lg" color="teal">
        "{auctionTitle}" 경매가 종료되었습니다.
      </Heading>
      <Heading size="lg">경매 결과</Heading>
      <Box display="flex" flexDir="column" gap="24px">
        {auctionTeamLeaders.map((leader) => {
          const { auctionMemberId, auctionTeamId, nickname, profileIconURL } =
            leader;
          const teamMemberIds = endAuctionData.teams
            .filter((member) => member.teamId === auctionTeamId)[0]
            .memberIds.filter((id) => id !== auctionMemberId);

          return (
            <Box key={auctionTeamId} display="flex" flexDir="column" gap="16px">
              <Heading size="md">{nickname}팀</Heading>
              <HStack>
                <Box
                  key={auctionMemberId}
                  w="180px"
                  display="flex"
                  flexDir="column"
                  alignItems="center"
                  position="relative"
                >
                  <Box
                    top="0"
                    left="30px"
                    bgColor="var(--chakra-colors-red-500)"
                    color="var(--chakra-colors-white)"
                    position="absolute"
                    p="1px 2px"
                    zIndex={9}
                  >
                    <Text fontSize="14px" fontWeight="600">
                      팀장
                    </Text>
                  </Box>
                  <Image
                    src={
                      profileIconURL
                        ? IMAGE_BASE_URL + profileIconURL
                        : UserIcon
                    }
                    alt="아이콘"
                    w="120px"
                    h="120px"
                    border="4px solid var(--chakra-colors-red-500)"
                  />
                  <Box w="180px">
                    <Text fontSize="16px" textAlign="center">
                      {nickname}
                    </Text>
                  </Box>
                </Box>
                {teamMemberIds.map((memberId) => {
                  const memberData = auctionMembers.data.filter(
                    (member) => member.auctionMemberId === memberId
                  )[0];
                  const { nickname, profileIconURL } = memberData;
                  return (
                    <Box
                      key={memberId}
                      w="180px"
                      display="flex"
                      flexDir="column"
                      alignItems="center"
                    >
                      <Image
                        src={
                          profileIconURL
                            ? IMAGE_BASE_URL + profileIconURL
                            : UserIcon
                        }
                        alt="아이콘"
                        w="120px"
                        h="120px"
                      />
                      <Box w="180px">
                        <Text fontSize="16px" textAlign="center">
                          {nickname}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </HStack>
            </Box>
          );
        })}
      </Box>
      <Box textAlign="center" marginTop="24px">
        <Button
          colorScheme="teal"
          size="lg"
          w="520px"
          onClick={() => window.location.replace("/auction")}
        >
          리스트로 돌아가기
        </Button>
      </Box>
    </Box>
  );
}
