import { Box, Text, Center, Image, Tooltip } from "@chakra-ui/react";
import { IMAGE_BASE_URL } from "../../../constants/url";
import UserIcon from "../../../icon/default-pepe-icon.png";
import { userProfilesType, userTeamRoleType } from "../../../apis/types/common";
import { wsEntryType } from "../../../apis/types/ws";

interface memberListType {
  /** 경매 맴버 id */
  auctionMemberId: number;
  /** 프로필 아이콘 url */
  profileIconURL: string | null;
  /** 경매 맴버 닉네임 */
  nickname: string;
  /** 팀원 룰  */
  teamRole: userTeamRoleType;
  /** 맴버 프로필 내용 */
  profileResponse: userProfilesType;
}

const AuctionMemberList = ({
  auctionMembers,
  enteredMembers,
  bidTimeEntry,
}: {
  auctionMembers: memberListType[];
  enteredMembers: number[] | undefined;
  bidTimeEntry: wsEntryType[] | undefined;
}) => {
  const presentTierColor = (presentTier: string) => {
    switch (presentTier) {
      case "에이스":
        return "var(--chakra-colors-red-400)";
      case "조커":
        return "var(--chakra-colors-purple-500)";
      case "골드":
        return "var(--chakra-colors-yellow-400)";
      case "실버":
        return "var(--chakra-colors-gray-400)";
      default:
        return "var(--chakra-colors-yellow-800)";
    }
  };

  /** 툴팁 라벨에 들어가는 reactnode형태 컴포넌트 */
  const tooltipLabelComponent = (data: memberListType) => {
    const {
      nickname,
      profileResponse: {
        mainPosition,
        subPosition,
        highestTier,
        presentTier,
        selfIntro,
        inGameNickname,
      },
    } = data;
    return (
      <div>
        {nickname}
        <br />
        인게임 : {inGameNickname}
        <br />
        현재 티어 : {presentTier}
        <br />
        최고 티어 : {highestTier}
        <br />
        주/부 포지션 : {mainPosition} / {subPosition}
        <br />
        <br />
        <Text maxW={180} minW={"auto"}>
          {selfIntro}
        </Text>
      </div>
    );
  };

  return (
    <Box
      overflow="auto"
      p="16px"
      border="1px solid var(--chakra-colors-gray-200)"
      borderRadius="16px"
      minH="100%"
    >
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap="16px">
        {auctionMembers.sort((row:memberListType , row1: memberListType) =>  {
            const mem1 = bidTimeEntry
            ? bidTimeEntry.filter((user) => user.id === row.auctionMemberId)[0]
                .auctionOrder
            : 1;
            const mem2 = bidTimeEntry
            ? bidTimeEntry.filter((user) => user.id === row1.auctionMemberId)[0]
                .auctionOrder
            : 2;
          return mem1 - mem2;
        }).flatMap((item, idx) => {
          const profileIconUrl = item.profileIconURL
            ? IMAGE_BASE_URL + item.profileIconURL
            : UserIcon;
          const isEntered = enteredMembers?.includes(item.auctionMemberId);
          const number = bidTimeEntry
            ? bidTimeEntry.filter((user) => user.id === item.auctionMemberId)[0]
                .auctionOrder
            : idx + 1;

          const tierColor = presentTierColor(item.profileResponse.presentTier);
          const border = "2px solid " + tierColor;

          return (
            <Tooltip
              hasArrow
              label={tooltipLabelComponent(item)}
              placement="right-start"
            >
              <Center
                key={item.auctionMemberId}
                h="68px"
                w="68px"
                borderTop={border}
                borderLeft={border}
                borderRight={border}
                position="relative"
              >
                <Box
                  top="0"
                  left="0"
                  bgColor="var(--chakra-colors-red-500)"
                  color="var(--chakra-colors-white)"
                  position="absolute"
                  p="1px 2px"
                  zIndex={9}
                >
                  <Text fontSize="14px" fontWeight="600">
                    {number || "입찰"}
                  </Text>
                </Box>
                <Box
                  bottom="0"
                  h="20px"
                  w="68px"
                  bgColor={tierColor}
                  position="absolute"
                  zIndex={9}
                >
                  <Text
                    color="var(--chakra-colors-white)"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontSize="12px"
                  >
                    {item.nickname}
                  </Text>
                </Box>
                <Image
                  src={profileIconUrl || ""}
                  w="68px"
                  h="68px"
                  filter={`grayscale(${isEntered ? 0 : 1})`}
                  opacity={isEntered ? 1 : 0.3}
                />
              </Center>
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
};

export { AuctionMemberList };
