import {
  Box,
  Text,
  Heading,
  Button,
  Image,
  Input,
  Skeleton,
} from "@chakra-ui/react";
import { resWsBidTimeType } from "../../../apis/types/ws";
import { useEffect, useState } from "react";
import { resGetAuctionsAuctionIdMembersType } from "../../../apis/types/auctions";
import { memberType } from "../../../apis/types/common";
import { IMAGE_BASE_URL } from "../../../constants/url";
import UserIcon from "../../../icon/default-pepe-icon.png";

const AuctionTarget = ({
  memberId,
  bidTimeWsResponse,
  auctionMembers,
  sendBiddingMessage,
  teamLeadersIds,
  auctionTeamLeaders,
  auctionCount,
}: {
  memberId: number;
  bidTimeWsResponse: resWsBidTimeType | undefined;
  auctionMembers: resGetAuctionsAuctionIdMembersType | undefined;
  sendBiddingMessage: (
    usePoint: number,
    targetId: number,
    bidder: number
  ) => void;
  teamLeadersIds: number[];
  auctionTeamLeaders: memberType[] | undefined;
  auctionCount: number;
}) => {
  const [bidderName, setBidderName] = useState("");
  const [usePoint, setUsePoint] = useState("");
  const [bidTime, setBidTime] = useState(30);
  const [targetData, setTargetData] = useState<memberType[]>();
  const [myTeamUsePoint, setMyTeamUsePoint] = useState(0);
  const [auctionUsePoint, setAuctionUsePoint] = useState(0);
  const [isFullMembers, setIsFullMembers] = useState(false);

  useEffect(() => {
    if (bidTimeWsResponse) {
      setBidTime(bidTimeWsResponse.time);
      setAuctionUsePoint(bidTimeWsResponse.usePoint);
      const myTeamData = bidTimeWsResponse.teams?.filter(
        (data) => data.leaderId === memberId
      );
      if (
        myTeamData &&
        myTeamData.length > 0 &&
        auctionCount - 1 === myTeamData[0]?.memberIds?.length
      ) {
        setIsFullMembers(true);
      }
      if (myTeamData) {
        setMyTeamUsePoint(
          myTeamData.length > 0 ? myTeamData[0].remainingPoint : 0
        );
      }
    }

    if (auctionMembers && auctionTeamLeaders) {
      const filteredUserData = auctionMembers.data.filter(
        (item) => item.auctionMemberId === bidTimeWsResponse?.targetId
      );
      const bidderUserData = auctionTeamLeaders.filter(
        (user) => user.auctionMemberId === bidTimeWsResponse?.bidder
      )[0];
      setTargetData(filteredUserData);
      setBidderName(bidderUserData?.nickname);
    }
  }, [
    auctionMembers,
    bidTimeWsResponse,
    auctionTeamLeaders,
    memberId,
    setBidderName,
    auctionCount,
  ]);

  const presentTierColor = (presentTier: string) => {
    switch (presentTier) {
      case "에이스":
        return "var(--chakra-colors-red-400)";
      case "조커":
        return "var(--chakra-colors-Purple-500)";
      case "골드":
        return "var(--chakra-colors-yellow-200)";
      case "실버":
        return "var(--chakra-colors-Gray-400)";
      default:
        return "var(--chakra-colors-yellow-800)";
    }
  };

  const makeSkeletonUi = () => {
    return <Skeleton h="100%" w="100%" borderRadius="16px" opacity="0.08" />;
  };

  if (!targetData || !targetData[0]) return makeSkeletonUi();

  const { nickname, profileResponse, profileIconURL, auctionMemberId } =
    targetData[0];
  const userIconUrl = profileIconURL
    ? IMAGE_BASE_URL + profileIconURL
    : UserIcon;
  const tierColor = presentTierColor(profileResponse.presentTier);
  return (
    <Box
      w="100%"
      h="100%"
      display="grid"
      justifyContent="space-between"
      boxShadow="0 0 0 2px teal inset"
      borderRadius="16px"
      p="16px"
    >
      <Box w="100%" display="grid" gap="16px" minW="380px">
        <Box
          display="flex"
          p="0 12px"
          justifyContent="space-between"
          alignItems="center"
          background="linear-gradient(to left, var(--chakra-colors-red-500), var(--chakra-colors-red-200))"
          color="var(--chakra-colors-white)"
          borderRadius="8px"
        >
          <Text fontWeight="600">경매중...</Text>
          <Text fontWeight="600">{bidTime}초</Text>
        </Box>
        <Box display="flex" flexDir="row" gap="12px" h="140px">
          <Image src={userIconUrl} alt="유저 아이콘" w="140px" h="140px" />
          <Box display="flex" flexDir="column" gap="4px">
            <Heading size="lg">{nickname}</Heading>
            <Box display="flex" flexDir="row">
              <Text
                w="28px"
                h="28px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                bgColor="teal"
                fontSize="18px"
                fontWeight="600"
                color="var(--chakra-colors-white)"
                mr="8px"
              >
                주
              </Text>
              <Text fontSize="18px" fontWeight="600">
                {profileResponse.mainPosition}
              </Text>
            </Box>
            <Box display="flex" flexDir="row">
              <Text
                w="28px"
                h="28px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                bgColor="var(--chakra-colors-gray-200)"
                fontSize="18px"
                fontWeight="600"
                color="var(--chakra-colors-gray-500)"
                mr="8px"
              >
                부
              </Text>
              <Text fontSize="18px" fontWeight="600">
                {profileResponse.subPosition}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box display="grid" gap="12px">
          <Box display="grid" gridTemplateColumns="1.7fr 3fr" gap="2px">
            <Text fontSize="18px" color="var(--chakra-colors-gray-500)">
              주 캐릭터:
            </Text>
            <Text fontSize="18px" fontWeight="600">
              {profileResponse.mainCharacter}
            </Text>
            <Text fontSize="18px" color="var(--chakra-colors-gray-500)">
              전체 최고 티어:
            </Text>
            <Text fontSize="18px" fontWeight="600">
              {profileResponse.highestTier}
            </Text>
            <Text fontSize="18px" color="var(--chakra-colors-gray-500)">
              현재 티어:
            </Text>
            <Text fontSize="18px" fontWeight="600" color={tierColor}>
              {profileResponse.presentTier}
            </Text>
            <Text fontSize="18px" color="var(--chakra-colors-gray-500)">
              인게임 닉네임:
            </Text>
            <Text fontSize="18px" fontWeight="600">
              {profileResponse.inGameNickname}
            </Text>
          </Box>
          <Box>
            <Text h="80px" overflow="auto" fontSize="18px" lineHeight="1.2">
              {profileResponse.selfIntro}
            </Text>
          </Box>
          <Box display="grid" gap="8px">
            <Text fontSize="18px" fontWeight="600">
              {bidTimeWsResponse?.bidder
                ? `${bidderName}팀 - 최고 입찰 ${bidTimeWsResponse.usePoint}p`
                : "현재 최고 입찰 없음"}
            </Text>
            {teamLeadersIds.includes(memberId) ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (isFullMembers) {
                    alert("더 이상 입찰 할 수 없습니다.");
                  } else if (auctionUsePoint + 5 > Number(usePoint)) {
                    alert("기존 입찰 포인트에서 5포인트 추가해주세요.");
                  } else {
                    myTeamUsePoint < Number(usePoint)
                      ? alert("입찰 포인트를 다시 입력해주세요.")
                      : sendBiddingMessage(
                          Number(usePoint),
                          auctionMemberId,
                          memberId
                        );
                  }
                  setUsePoint("");
                }}
              >
                <Box display="grid" gap="8px" gridTemplateColumns="2fr 1fr">
                  <Input
                    type="number"
                    value={usePoint}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^0/.test(value) && /^\d+$/.test(value)) {
                        // 앞의 0을 모두 제거하고 나서 빈 문자열이 되면 '0'으로 변환
                        return setUsePoint(value.replace(/^0+/, "") || "0");
                      } else {
                        // 숫자가 아닌 경우 원래 문자열을 반환
                        return setUsePoint(value);
                      }
                    }}
                  />
                  <Button colorScheme="teal" type="submit">
                    입찰
                  </Button>
                </Box>
              </form>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AuctionTarget };
