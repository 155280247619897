import { Badge, Box, Heading, Image, Text } from "@chakra-ui/react";
import UserIcon from "../../../icon/default-pepe-icon.png";
import { useEffect, useState } from "react";
import { resGetAuctionsAuctionIdMembersType } from "../../../apis/types/auctions";
import { IMAGE_BASE_URL } from "../../../constants/url";
import { memberType } from "../../../apis/types/common";

const AuctionTeamList = ({
  auctionMembers,
  memberId,
  auctionTeamLeaders,
}: {
  auctionMembers: resGetAuctionsAuctionIdMembersType | undefined;
  memberId: number;
  auctionTeamLeaders: memberType[] | undefined;
}) => {
  const userId = localStorage.getItem("userId");
  const [teamsData, setTeamsData] = useState<memberType[]>();

  useEffect(() => {
    if (auctionMembers && auctionTeamLeaders) {
      const myTeamData = auctionTeamLeaders?.filter(
        (item) => item.auctionMemberId === memberId
      );
      const lastTeamData = auctionTeamLeaders?.filter(
        (item) => item.auctionMemberId !== memberId
      );
      setTeamsData([...myTeamData, ...lastTeamData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionMembers, auctionTeamLeaders]);

  return (
    <Box display="flex" h="500px" flexDir="column" gap="8px" overflow="auto">
      {teamsData
        ? teamsData.map((data) => {
            const isMyTeam = data.userId === Number(userId);
            const leaderNickname = data.nickname;
            const leaderUserIcon = data.profileIconURL
              ? IMAGE_BASE_URL + data.profileIconURL
              : UserIcon;
            return (
              <Box
                key={data.auctionTeamId}
                borderWidth="1px"
                borderStyle="solid"
                borderColor={
                  isMyTeam
                    ? "var(--chakra-colors-teal-500)"
                    : "var(--chakra-colors-gray-300)"
                }
                borderRadius="16px"
                boxSizing="border-box"
                p="12px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDir="row"
                  justifyContent="space-between"
                  pb="4px"
                >
                  <Heading size="sm" display="flex">
                    {data.nickname + "팀"}
                    {isMyTeam ? (
                      <Badge
                        borderRadius="8px"
                        ml="8px"
                        colorScheme="red"
                        lineHeight="1rem"
                      >
                        MY
                      </Badge>
                    ) : null}
                  </Heading>
                  <Box display="flex" gap="8px" alignItems="center">
                    <Text
                      fontSize="16px"
                      display="flex"
                      justifyContent="center"
                      gap="8px"
                    >
                      잔여 :{" "}
                      <Badge colorScheme="teal" fontSize="14px">
                        {/* {data.remainingPoints} */}
                        1000 P
                      </Badge>
                    </Text>
                  </Box>
                </Box>
                <Box display="flex" flexDir="row" gap="16px">
                  <Box
                    w="68px"
                    h="68px"
                    display="flex"
                    flexDir="column"
                    justifyContent="left"
                    position="relative"
                  >
                    <Image
                      src={leaderUserIcon}
                      alt="팀장 아이콘"
                      w="68px"
                      h="68px"
                    />
                    <Box
                      bottom="0"
                      h="20px"
                      w="68px"
                      bgColor="var(--chakra-colors-blackAlpha-700)"
                      position="absolute"
                    >
                      <Text
                        color="var(--chakra-colors-white)"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        fontSize="12px"
                      >
                        {leaderNickname}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        : null}
    </Box>
  );
};

export { AuctionTeamList };
