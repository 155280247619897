import {
  ID_PATTERN,
  PW_PATTERN,
  NICKNAME_PATTERN,
} from "../../../constants/regExp";

export const handleChangeInputState = (
  e: React.ChangeEvent<HTMLInputElement>,
  setInputValue: React.Dispatch<React.SetStateAction<string>>,
  setErrorState: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >
) => {
  const value = e.target.value;
  setInputValue(value);
  setErrorState((prevState) => ({
    ...prevState,
    [e.target.name]: value.length === 0,
  }));
};

export const signUpValidation = (
  loginId: string,
  loginPassword: string,
  inputConfirmPwValue: string,
  inputNicknameValue: string,
  setErrorState: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >
) => {
  const errors = {
    inputId: !ID_PATTERN.test(loginId),
    inputPw: !PW_PATTERN.test(loginPassword),
    inputConfirmPw: !PW_PATTERN.test(inputConfirmPwValue),
    inputNickname: !NICKNAME_PATTERN.test(inputNicknameValue),
  };

  if (Object.values(errors).some((error) => error)) {
    setErrorState((prevState) => ({
      ...prevState,
      ...errors,
    }));
    return false;
  }

  if (loginPassword !== inputConfirmPwValue) return false;
  return true;
};

export const signInValidation = (loginId: string, loginPassword: string) => {
  if (!ID_PATTERN.test(loginId) || !PW_PATTERN.test(loginPassword))
    return false;
  return true;
};
