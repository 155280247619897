import {
  Text,
  Box,
  TabPanel,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import ProfileRadioGroup from "./ProfileRadioGroup";
import { SetStateAction, useEffect, useState } from "react";
import {
  cyphersTierType,
  cyphersPositionType,
  userProfilesType,
  gameType,
  apiResponseCommonType,
} from "../../apis/types/common";
import { instance } from "../../apis/axios/instance";
import {
  reqPostUserProfilesType,
  reqPutUserProfilesType,
} from "../../apis/types/user";
import {
  handleChangeInputState,
  handleReload,
  profilesUpdateValidation,
} from "../../pages/profile/handler";

export default function ProfileTabPanels({
  gameType,
  isEdit,
  setIsEdit,
  gameprofileData,
}: {
  gameType: gameType;
  isEdit: boolean;
  setIsEdit: React.Dispatch<SetStateAction<boolean>>;
  gameprofileData: userProfilesType[] | [] | undefined;
}) {
  const [presentTier, setPresentTier] = useState<cyphersTierType>(
    cyphersTierType.에이스
  );
  const [highestTier, setHighestTier] = useState<cyphersTierType>(
    cyphersTierType.에이스
  );
  const [mainPosition, setMainPosition] = useState<cyphersPositionType>(
    cyphersPositionType.올라운더
  );
  const [subPosition, setSubPosition] = useState<cyphersPositionType>(
    cyphersPositionType.탱커
  );
  const [inGameNickname, setInGameNickname] = useState("");
  const [mainCharacter, setMainCharacter] = useState("");
  const [selfIntro, setSelfIntro] = useState("");

  const postUserPofilesApiFetch = async (req: reqPostUserProfilesType) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.post(`/v1/user/profiles`, req, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 400) {
      return alert(result.resultMessage);
    } else {
      setIsEdit(false);
      handleReload();
    }
  };

  const putUserPofilesApiFetch = async (req: reqPutUserProfilesType) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.put(`/v1/user/profiles`, req, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 400) {
      return alert(result.resultMessage);
    } else {
      setIsEdit(false);
      handleReload();
    }
  };

  useEffect(() => {
    if (gameprofileData) {
      if (gameprofileData.length === 0) {
        setIsEdit(true);
      } else {
        const profiles = gameprofileData[0];
        setPresentTier(profiles.presentTier);
        setHighestTier(profiles.highestTier);
        setMainPosition(profiles.mainPosition);
        setSubPosition(profiles.subPosition);
        setInGameNickname(profiles.inGameNickname);
        setMainCharacter(profiles.mainCharacter);
        setSelfIntro(profiles.selfIntro);
      }
    }
  }, [setIsEdit, gameprofileData]);

  return (
    <TabPanel p="16px" h="560px">
      <Box display="grid" gridTemplateColumns="1.7fr 10fr" gap="24px" pb="24px">
        <ProfileRadioGroup
          radioText={"현재 티어"}
          radioContents={
            [
              cyphersTierType.에이스,
              cyphersTierType.조커,
              cyphersTierType.골드,
              cyphersTierType.실버,
              cyphersTierType.브론즈,
            ] as cyphersTierType[]
          }
          radioValue={presentTier}
          setRadioValue={setPresentTier}
          isAllRadioReadOnly={!isEdit}
        />
        <ProfileRadioGroup
          radioText={"최고 티어"}
          radioContents={
            [
              cyphersTierType.에이스,
              cyphersTierType.조커,
              cyphersTierType.골드,
              cyphersTierType.실버,
              cyphersTierType.브론즈,
            ] as cyphersTierType[]
          }
          radioValue={highestTier}
          setRadioValue={setHighestTier}
          isAllRadioReadOnly={!isEdit}
        />
        <ProfileRadioGroup
          radioText={"주 포지션"}
          radioContents={
            [
              cyphersPositionType.올라운더,
              cyphersPositionType.탱커,
              cyphersPositionType.근딜,
              cyphersPositionType.원딜,
              cyphersPositionType.서폿,
            ] as cyphersPositionType[]
          }
          radioValue={mainPosition}
          setRadioValue={setMainPosition}
          isAllRadioReadOnly={!isEdit}
        />
        <ProfileRadioGroup
          radioText={"부 포지션"}
          radioContents={
            [
              cyphersPositionType.탱커,
              cyphersPositionType.근딜,
              cyphersPositionType.원딜,
              cyphersPositionType.서폿,
            ] as cyphersPositionType[]
          }
          radioValue={subPosition}
          setRadioValue={setSubPosition}
          isAllRadioReadOnly={!isEdit}
        />
      </Box>
      <Divider />
      <Box display="grid" gap="16px" pt="16px">
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="1fr 5fr"
          p="8px 0"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            인게임 닉네임
          </FormLabel>
          {isEdit ? (
            <Input
              type="text"
              size="md"
              value={inGameNickname}
              onChange={(e) => handleChangeInputState(e, setInGameNickname)}
            />
          ) : (
            <Text>{inGameNickname}</Text>
          )}
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="1fr 5fr"
          p="8px 0"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            주 캐릭터
          </FormLabel>
          {isEdit ? (
            <Input
              type="text"
              size="md"
              value={mainCharacter}
              onChange={(e) => handleChangeInputState(e, setMainCharacter)}
            />
          ) : (
            <Text>{mainCharacter}</Text>
          )}
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="1fr 5fr"
          p="8px 0"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            자기소개
          </FormLabel>
          {isEdit ? (
            <Textarea
              value={selfIntro}
              onChange={(e) => handleChangeInputState(e, setSelfIntro)}
            />
          ) : (
            <Text maxH="80px" overflow="auto">
              {selfIntro}
            </Text>
          )}
        </FormControl>
        <Box
          display="grid"
          gridTemplateColumns={
            isEdit && gameprofileData && gameprofileData?.length > 0
              ? "520px 520px"
              : "520px"
          }
          justifyContent="center"
          gap="16px"
          p="16px"
        >
          {isEdit && gameprofileData ? (
            <>
              {gameprofileData.length !== 0 ? (
                <Button
                  size="lg"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  취소
                </Button>
              ) : null}
              <Button
                size="lg"
                colorScheme="teal"
                onClick={() => {
                  if (
                    profilesUpdateValidation(
                      inGameNickname,
                      mainCharacter,
                      selfIntro
                    )
                  ) {
                    if (gameprofileData.length === 0) {
                      // 최초 접속
                      postUserPofilesApiFetch({
                        gameName: gameType,
                        presentTier,
                        highestTier,
                        mainPosition,
                        subPosition,
                        inGameNickname,
                        mainCharacter,
                        selfIntro,
                      });
                    } else {
                      putUserPofilesApiFetch({
                        gameName: gameType,
                        presentTier,
                        highestTier,
                        mainPosition,
                        subPosition,
                        inGameNickname,
                        mainCharacter,
                        selfIntro,
                      });
                    }
                  }
                }}
              >
                저장
              </Button>
            </>
          ) : (
            <Button
              size="lg"
              colorScheme="teal"
              onClick={() => setIsEdit(true)}
            >
              수정
            </Button>
          )}
        </Box>
      </Box>
    </TabPanel>
  );
}
