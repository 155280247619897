import { SetStateAction } from "react";
import {
  INGAME_NICKNAME_PATTERN,
  MAIN_CHARACTER_PATTERN,
  SELF_INTRO_PATTERN,
} from "../../../constants/regExp";

export const profilesUpdateValidation = (
  inGameNickname: string,
  mainCharacter: string,
  selfIntro: string
) => {
  if (!INGAME_NICKNAME_PATTERN.test(inGameNickname)) {
    return alert("인게임 닉네임은 1~16자로 입력해주세요.");
  }
  if (!MAIN_CHARACTER_PATTERN.test(mainCharacter)) {
    return alert("주 캐릭터는 1~26자로 입력해주세요.");
  }
  if (!SELF_INTRO_PATTERN.test(selfIntro)) {
    return alert("자기 소개는 150자이내로 입력해주세요.");
  }
  return true;
};

export const handleReload = () => {
  window.location.replace("/");
};

export const handleChangeInputState = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setState: React.Dispatch<SetStateAction<string>>
) => {
  setState(e.target.value);
};
