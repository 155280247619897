import { Box, Button, Text, Input } from "@chakra-ui/react";
import { useState } from "react";

const AuctionChatRoom = ({
  title,
  chatData,
  sendMessage,
  isValidChatRoom,
}: {
  title: "팀" | "전체";
  chatData: string[];
  sendMessage: (chat: string) => void;
  isValidChatRoom: boolean;
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      <Box
        background="var(--chakra-colors-blackAlpha-50)"
        display="flex"
        flexDir="column-reverse"
        h="150px"
        overflowY="auto"
        gap="4px"
      >
        {chatData.flatMap((chatMsg, idx) => (
          <Box key={idx} display="flex" m="0 4px">
            <Text fontSize="16px">{chatMsg}</Text>
          </Box>
        ))}
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (inputValue !== "") {
            sendMessage(inputValue);
            setInputValue("");
          }
        }}
      >
        <Box display="flex" m="8px 0" gap="8px" alignItems="center">
          <Text w="60px" fontSize="16px">
            {title + " : "}
          </Text>
          <Input
            isDisabled={!isValidChatRoom}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Button type="submit" w="120px" isDisabled={!isValidChatRoom}>
            입력
          </Button>
        </Box>
      </form>
    </>
  );
};

export { AuctionChatRoom };
