import {
  Heading,
  Box,
  UnorderedList,
  Text,
  ListItem,
  Badge,
  Button,
} from "@chakra-ui/react";
import { SetStateAction } from "react";
import { resWsEnterMemberType } from "../../../apis/types/ws";

const WaitingMembers = ({
  setIsWaitingMembers,
  enterMemberWsResponse,
  hostUserId,
  sendStartMessage,
}: {
  setIsWaitingMembers: React.Dispatch<SetStateAction<boolean>>;
  enterMemberWsResponse: resWsEnterMemberType;
  hostUserId: number;
  sendStartMessage: () => void;
}) => {
  const {
    memberCount,
    mustMemberCount,
    enterMemberCount,
    enterMustMemberCount,
    enteredMembers,
    enteredLeaders,
    isEnterHost,
  } = enterMemberWsResponse;
  const userId = window.localStorage.getItem("userId");
  const ENTERED_ALL_MEMBERS_COUNT =
    (enteredMembers?.length || 0) + (enteredLeaders?.length || 0);
  const ALL_MEMBERS_COUNT = memberCount + mustMemberCount + 1;

  const handleStartAuction = () => {
    if (hostUserId !== Number(userId)) {
      return alert("경매 시작은 방장만이 할 수 있습니다.");
    }
    sendStartMessage();
    setIsWaitingMembers(false);
  };

  const handleStartAuctionButtionDisabled = () => {
    if (hostUserId !== Number(userId)) {
      return true;
    }
    if (mustMemberCount === 0) return true;
    if (mustMemberCount !== enterMustMemberCount) return true;
    return false;
  };

  return (
    <Box
      w="100%"
      h="100%"
      display="grid"
      justifyContent="space-between"
      boxShadow="0 0 0 2px teal inset"
      borderRadius="16px"
      p="16px"
    >
      <Box w="100%" display="flex" flexDir="column">
        <Box display="flex" flexDir="column" gap="24px">
          <Heading size="md">경매 팀장 및 멤버를 기다리고 있습니다.</Heading>
          <Text>
            입장현황 {ENTERED_ALL_MEMBERS_COUNT + (isEnterHost ? 1 : 0)} 명 입장
            / {ALL_MEMBERS_COUNT}명
          </Text>
          <UnorderedList>
            <ListItem>
              방장 {isEnterHost ? 1 : 0}/1
              <Badge colorScheme="red" fontSize="14px">
                필수
              </Badge>
            </ListItem>
            <ListItem>
              팀장 {enterMustMemberCount}/{mustMemberCount}
              <Badge colorScheme="red" fontSize="14px">
                필수
              </Badge>
            </ListItem>
            <ListItem>
              팀원 {enterMemberCount}/{memberCount}
            </ListItem>
          </UnorderedList>
          <Text>팀장이 모두 모이면 방장이 경매시작을 할 수 있습니다.</Text>
        </Box>
        <Button
          w="100%"
          mt="150px"
          size="lg"
          colorScheme="teal"
          variant="solid"
          isDisabled={handleStartAuctionButtionDisabled()}
          onClick={handleStartAuction}
        >
          {hostUserId !== Number(userId)
            ? "경매 시작 기다리는 중..."
            : "경매 시작"}
        </Button>
      </Box>
    </Box>
  );
};

export { WaitingMembers };
