import {
  Heading,
  Card,
  Text,
  CardBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { reqPutUserPasswordType } from "../../apis/types/user";
import { instance } from "../../apis/axios/instance";
import { useState } from "react";
import { PW_PATTERN } from "../../constants/regExp";
import { checkAuthenticationToken } from "../../utils/api";
import { apiResponseCommonType } from "../../apis/types/common";

export const SuccessFindPassword = ({
  passwordKey,
}: {
  passwordKey: string;
}) => {
  const navigate = useNavigate();
  const [loginPassword, setLoginPassword] = useState("");
  const [loginPasswordConfirm, setLoginPasswordConfirm] = useState("");
  const [isError, setIsError] = useState({
    inputPw: false,
    inputConfirmPw: false,
  });

  const onChangeUserPasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!PW_PATTERN.test(loginPassword) || loginPassword === "") {
      setIsError({
        ...isError,
        inputPw: true,
      });
      return alert("입력한 값을 확인해주세요.");
    }
    if (loginPassword !== loginPasswordConfirm) {
      setIsError({
        ...isError,
        inputConfirmPw: true,
      });
      return alert("입력한 값을 확인해주세요.");
    }

    putUserPasswordApiFetch({
      loginPassword,
      loginPasswordVerify: loginPasswordConfirm,
      passwordKey,
    });
  };

  const putUserPasswordApiFetch = async (req: reqPutUserPasswordType) => {
    const response = await instance.put(`/v1/user/password`, req);
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 200) {
      alert("비밀번호가 변경되었습니다. 로그인 페이지로 이동합니다.");
      return navigate("/signin");
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  return (
    <>
      <Heading pb="32px">비밀번호 변경하기</Heading>
      <Card variant="filled" textAlign="left" mb="24px">
        <CardBody>
          <Text fontSize="16px">
            키 값 인증에 성공하였습니다. 변경할 비밀번호를 입력해주세요.
          </Text>
        </CardBody>
      </Card>
      <form onSubmit={(e) => onChangeUserPasswordSubmit(e)}>
        <FormControl
          isInvalid={isError.inputPw}
          display="grid"
          alignItems="center"
          gridTemplateColumns="3fr 10fr"
          pb="24px"
        >
          <FormLabel alignItems="center">비밀번호</FormLabel>
          <Input
            type="password"
            size="lg"
            value={loginPassword}
            placeholder="9 ~ 20자 / 영대문자, 영소문자, 숫자, 특수문자(!?@%^*+=) 포함"
            onChange={(e) => {
              setIsError({
                ...isError,
                inputPw: false,
              });
              setLoginPassword(e.target.value);
            }}
          />
          {isError.inputPw && (
            <FormErrorMessage pl="136px" gridColumn="1/3" textAlign="left">
              패스워드를 입력해주세요. 9 ~ 20자 / 영대문자, 영소문자, 숫자,
              특수문자(!?@%^*+=) 포함
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          isInvalid={isError.inputConfirmPw}
          display="grid"
          alignItems="center"
          gridTemplateColumns="3fr 10fr"
          pb="24px"
        >
          <FormLabel alignItems="center">비밀번호 확인</FormLabel>
          <Input
            type="password"
            size="lg"
            value={loginPasswordConfirm}
            placeholder="위 비밀번호와 동일하게 입력해주세요."
            onChange={(e) => {
              setIsError({
                ...isError,
                inputConfirmPw: false,
              });
              setLoginPasswordConfirm(e.target.value);
            }}
          />
          {isError.inputConfirmPw && (
            <FormErrorMessage pl="136px" gridColumn="1/3" textAlign="left">
              패스워드 확인을 입력해주세요.
            </FormErrorMessage>
          )}
        </FormControl>
        <Button type="submit" colorScheme="teal" size="lg" w="100%">
          비밀번호 변경
        </Button>
      </form>
    </>
  );
};
