import SideBar from "./components/SideBar";
import { ChakraProvider } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Appwrapper from "./components/AppWrapper";
import ContentsLayout from "./components/ContentsLayout";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const title = location.pathname === "/" ? "프로필" : "경매";
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!accessToken) {
      return navigate("/signin");
    }
  }, [accessToken, navigate]);

  return (
    <ChakraProvider>
      <Appwrapper>
        {/* 왼쪽 SideBar 고정 */}
        <SideBar />
        <ContentsLayout title={title}>
          <Outlet />
        </ContentsLayout>
      </Appwrapper>
    </ChakraProvider>
  );
}

export default App;
