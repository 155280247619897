import {
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { handleChangeInputState, signUpValidation } from "./handler";
import { SuccessSignUp } from "../../components/Authorization/SuccessSignUp";
import { instance } from "../../apis/axios/instance";
import {
  reqGetUserDuplicatedType,
  resGetUserDuplicatedType,
  reqPostUserType,
  resPostUserType,
} from "../../apis/types/user";
import { ID_PATTERN, NICKNAME_PATTERN } from "../../constants/regExp";

const INPUT_VALIDATION_CHECK_MSG = {
  ID: "영소문자, 숫자로 이루어진 6 ~ 20자" as const,
  PW: "영소문자, 숫자, 특수문자 포함 8 ~ 20자 사이" as const,
  CONFIRM_PW: "위 비밀번호와 동일하게 입력해주세요." as const,
  NICKNAME: "닉네임을 입력해주세요. (2 ~ 10자)" as const,
};

export default function SignUp() {
  const [isSuccessSignUp, setIsSuccessSignUp] = useState(false);
  const [inputIdValue, setInputIdValue] = useState("");
  const [inputPwValue, setInputPwValue] = useState("");
  const [inputConfirmPwValue, setInputConfirmPwValue] = useState("");
  const [inputNicknameValue, setInputNicknameValue] = useState("");
  const [passwordKey, setPasswordKey] = useState("");
  const [isError, setIsError] = useState<{ [key: string]: boolean }>({
    inputId: false,
    inputPw: false,
    inputConfirmPw: false,
    inputNickname: false,
  });
  const [isNicknameAndIdDuplicateChecked, setIsNicknameAndIdDuplicateChecked] =
    useState({
      isCheckedId: false,
      isCheckedNickname: false,
    });

  const checkDuplicationId = () => {
    if (inputIdValue.length === 0 || !ID_PATTERN.test(inputIdValue)) {
      setIsError({ ...isError, inputId: true });
    } else {
      getUserDuplicatedApiFetch({ loginId: inputIdValue }, "아이디");
    }
  };

  const checkDuplicationNickname = () => {
    if (
      inputNicknameValue.length === 0 ||
      !NICKNAME_PATTERN.test(inputNicknameValue)
    ) {
      setIsError({ ...isError, inputNickname: true });
    } else {
      getUserDuplicatedApiFetch({ nickname: inputNicknameValue }, "닉네임");
    }
  };

  const getUserDuplicatedApiFetch = async (
    req: reqGetUserDuplicatedType,
    type: "아이디" | "닉네임"
  ) => {
    const response = await instance.get(`/v1/user/duplicated`, {
      params: { ...req },
    });
    const result = response.data as resGetUserDuplicatedType;
    if (result.resultCode === 400) {
      type === "닉네임" ? setInputNicknameValue("") : setInputIdValue("");
      alert(`${result.resultMessage}. 다른 ${type}을 사용해주세요.`);
    } else {
      alert(`사용가능한 ${type}입니다.`);
      setIsNicknameAndIdDuplicateChecked({
        ...isNicknameAndIdDuplicateChecked,
        ...(type === "아이디" && { isCheckedId: true }),
        ...(type === "닉네임" && { isCheckedNickname: true }),
      });
    }
  };

  const postUserApiFetch = async (req: reqPostUserType) => {
    const response = await instance.post(`/v1/user`, req);
    const result = response.data as resPostUserType;
    if (result.resultCode === 400) {
      alert(result.resultMessage);
    } else {
      if (result.data) {
        setPasswordKey(result.data?.passwordKey);
        setIsSuccessSignUp(true);
      }
    }
  };

  return !isSuccessSignUp ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        width="960px"
        p="120px"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        height="auto"
        textAlign="center"
      >
        <Heading pb="32px">회원가입</Heading>
        <Box display="grid" pb="24px">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (
                !isNicknameAndIdDuplicateChecked.isCheckedId ||
                !isNicknameAndIdDuplicateChecked.isCheckedNickname
              ) {
                return alert("아이디, 닉네임 중복 확인을 해주세요.");
              }
              if (
                signUpValidation(
                  inputIdValue,
                  inputPwValue,
                  inputConfirmPwValue,
                  inputNicknameValue,
                  setIsError
                )
              ) {
                postUserApiFetch({
                  loginId: inputIdValue,
                  loginPassword: inputPwValue,
                  loginPasswordVerify: inputConfirmPwValue,
                  nickname: inputNicknameValue,
                });
              } else {
                alert("입력 값을 확인해주세요.");
              }
            }}
          >
            <FormControl
              isInvalid={isError.inputId}
              display="grid"
              alignItems="center"
              gridTemplateColumns="1.4fr 6fr"
              pb="24px"
              isRequired
            >
              <FormLabel alignItems="center">아이디</FormLabel>
              <Box display="grid" gridTemplateColumns="3fr 1fr" gap="16px">
                <Input
                  type="text"
                  size="lg"
                  name="inputId"
                  value={inputIdValue}
                  placeholder={INPUT_VALIDATION_CHECK_MSG.ID}
                  onChange={(e) => {
                    handleChangeInputState(e, setInputIdValue, setIsError);
                    setIsNicknameAndIdDuplicateChecked({
                      ...isNicknameAndIdDuplicateChecked,
                      isCheckedId: false,
                    });
                  }}
                />
                <Button
                  onClick={checkDuplicationId}
                  colorScheme="teal"
                  size="lg"
                  w="100%"
                  variant="outline"
                  isDisabled={isNicknameAndIdDuplicateChecked.isCheckedId}
                >
                  중복확인
                </Button>
              </Box>
              {isError.inputId && (
                <FormErrorMessage pl="136px" gridColumn="1/3">
                  {`아이디를 입력해주세요. ${INPUT_VALIDATION_CHECK_MSG.ID}`}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isInvalid={isError.inputPw}
              display="grid"
              alignItems="center"
              gridTemplateColumns="1.4fr 6fr"
              pb="24px"
              isRequired
            >
              <FormLabel>패스워드</FormLabel>
              <Input
                type="password"
                size="lg"
                name="inputPw"
                value={inputPwValue}
                placeholder={INPUT_VALIDATION_CHECK_MSG.PW}
                onChange={(e) =>
                  handleChangeInputState(e, setInputPwValue, setIsError)
                }
              />
              {isError.inputPw && (
                <FormErrorMessage pl="136px" gridColumn="1/3">
                  {`패스워드를 입력해주세요. ${INPUT_VALIDATION_CHECK_MSG.PW}`}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isInvalid={isError.inputConfirmPw}
              display="grid"
              alignItems="center"
              gridTemplateColumns="1.4fr 6fr"
              pb="24px"
              isRequired
            >
              <FormLabel>패스워드 확인</FormLabel>
              <Input
                type="password"
                size="lg"
                name="inputConfirmPw"
                value={inputConfirmPwValue}
                placeholder={INPUT_VALIDATION_CHECK_MSG.CONFIRM_PW}
                onChange={(e) =>
                  handleChangeInputState(e, setInputConfirmPwValue, setIsError)
                }
              />
              {isError.inputConfirmPw && (
                <FormErrorMessage pl="136px" gridColumn="1/3">
                  {INPUT_VALIDATION_CHECK_MSG.CONFIRM_PW}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isInvalid={isError.inputNickname}
              display="grid"
              alignItems="center"
              gridTemplateColumns="1.4fr 6fr"
              pb="24px"
              isRequired
            >
              <FormLabel>닉네임</FormLabel>
              <Box display="grid" gridTemplateColumns="3fr 1fr" gap="16px">
                <Input
                  type="text"
                  size="lg"
                  name="inputNickname"
                  value={inputNicknameValue}
                  placeholder={INPUT_VALIDATION_CHECK_MSG.NICKNAME}
                  onChange={(e) => {
                    handleChangeInputState(
                      e,
                      setInputNicknameValue,
                      setIsError
                    );
                    setIsNicknameAndIdDuplicateChecked({
                      ...isNicknameAndIdDuplicateChecked,
                      isCheckedNickname: false,
                    });
                  }}
                />
                <Button
                  onClick={checkDuplicationNickname}
                  colorScheme="teal"
                  size="lg"
                  w="100%"
                  variant="outline"
                  isDisabled={isNicknameAndIdDuplicateChecked.isCheckedNickname}
                >
                  중복확인
                </Button>
              </Box>
              {isError.inputNickname && (
                <FormErrorMessage pl="136px" gridColumn="1/3">
                  {INPUT_VALIDATION_CHECK_MSG.NICKNAME}
                </FormErrorMessage>
              )}
            </FormControl>
            <Button type="submit" colorScheme="teal" size="lg" w="100%">
              회원가입
            </Button>
          </form>
        </Box>
        <Card variant="filled">
          <CardBody>
            <Text align="left" fontSize="16px">
              저희는 어떠한 개인 정보도 수집하지 않습니다.
              <br />
              다만 서비스 관리와 경매 결과 저장을 위해 회원 가입을 요청드립니다.
            </Text>
          </CardBody>
        </Card>
        <Box
          w="300px"
          m="0 auto"
          pt="24px"
          display="grid"
          gridTemplateColumns="1fr 0.2fr 1fr"
          gridColumnGap="12px"
        >
          <Link href="/signin">로그인</Link>|
          <Link href="/findPassword">비밀번호 찾기</Link>
        </Box>
      </Box>
    </Box>
  ) : (
    <SuccessSignUp passwordKey={passwordKey} />
  );
}
