import {
  Box,
  Button,
  Text,
  FormControl,
  FormLabel,
  Image,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Divider,
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import UserIcon from "../../icon/default-pepe-icon.png";
import { formDataInstance, instance } from "../../apis/axios/instance";
import { useNavigate } from "react-router-dom";
import { resGetUserType } from "../../apis/types/user";
import { apiResponseCommonType, gameType } from "../../apis/types/common";
import ProfileTabPanel from "../../components/Profile/ProfileTabPanels";
import { handleReload } from "./handler";
import { IMAGE_BASE_URL } from "../../constants/url";
import { checkAuthenticationToken } from "../../utils/api";

export default function Profile() {
  let inputFileRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [userIcon, setUserIcon] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [fileData, setFileData] = useState<File>();
  const [isEdit, setIsEdit] = useState(false);
  const [userProfileData, setUserProfileData] = useState<resGetUserType>();
  const [currentTabState, setCurrentTabState] = useState<gameType | string>(
    gameType.사이퍼즈
  );

  const handleButtonClick = () => {
    inputFileRef.current?.click();
  };

  const handleFileSelect = () => {
    if (inputFileRef.current?.files) {
      const file = inputFileRef.current.files;
      setFileData(file[0]);
      if (file.length > 0) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onloadend = () => {
          setSelectedFile(reader.result as string);
        };
      }
    }
  };

  const getUserApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.get(`/v1/user`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = response.data as resGetUserType;
    if (result.resultCode === 200) {
      setUserProfileData(result);
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  const putUserIconApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (fileData) {
      const formData = new FormData();
      formData.append("icon", fileData);
      const response = await formDataInstance.put(
        `/v1/user/icon`,
        {
          icon: formData.get("icon"),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const result = response.data as resGetUserType;
      if (result.resultCode === 200) {
        alert("아이콘이 변경되었습니다.");
        handleReload();
      } else {
        // TODO : 사이즈 api 태우지않고 alert 띄우는 방식으로 수정
        checkAuthenticationToken(result, navigate);
      }
    }
  };

  const postLogoutApiFetch = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await instance.post(`/v1/logout`, undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = response.data as apiResponseCommonType<null>;
    if (result.resultCode === 200) {
      localStorage.clear();
      alert("정상적으로 로그아웃 되었습니다. 로그인 페이지로 이동합니다.");
      navigate("/signin");
    } else {
      checkAuthenticationToken(result, navigate);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) getUserApiFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfileData && userProfileData.data?.profileIconURL) {
      setUserIcon(IMAGE_BASE_URL + userProfileData.data.profileIconURL);
    } else {
      setUserIcon(UserIcon);
    }
  }, [userProfileData]);

  return (
    <>
      <Box display="grid" gap="16px" gridTemplateColumns="2fr 3fr">
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="100px 500px"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            닉네임
          </FormLabel>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr="50px"
          >
            <Text as="b" alignItems="center">
              {userProfileData?.data?.nickname}
            </Text>
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={() =>
                window.confirm("로그아웃 하시겠습니까?")
                  ? postLogoutApiFetch()
                  : null
              }
            >
              로그아웃
            </Button>
          </Box>
        </FormControl>
        <FormControl
          display="grid"
          alignItems="center"
          gridTemplateColumns="100px 500px"
          gap="8px"
        >
          <FormLabel alignItems="center" mb="0" fontSize="20px">
            아이콘
          </FormLabel>
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="16px">
            <Text alignSelf="center">
              <Image
                src={selectedFile || userIcon}
                alt="user icon"
                w="48px"
                h="48px"
              />
            </Text>
            <input
              type="file"
              accept="image/*"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
            <Button size="lg" gap="16px" onClick={handleButtonClick}>
              아이콘 변경
            </Button>
            <Button
              size="lg"
              gap="16px"
              colorScheme="teal"
              onClick={putUserIconApiFetch}
            >
              아이콘 저장
            </Button>
          </Box>
        </FormControl>
      </Box>
      <Divider />
      <Box>
        <Tabs size="md" variant="enclosed">
          <TabList>
            {["사이퍼즈", "LoL", "오버워치"].map((title) => (
              <Tab
                onClick={(e) => setCurrentTabState(e.currentTarget.innerHTML)}
                as={currentTabState === title ? "b" : undefined}
                key={title}
                p="12px 0"
                fontSize="lg"
                w="240px"
              >
                {title}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <ProfileTabPanel
              gameType={gameType.사이퍼즈}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              gameprofileData={userProfileData?.data?.profilesResponse.filter(
                (item) => item.gameName === gameType.사이퍼즈
              )}
            />
            {/* <ProfileTabPanel
                gameType={gameType.LOL}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                gameprofileData={userProfileData?.data.profilesResponse.filter(
                  (item) => item.game === "LOL"
                )}
              />
              <ProfileTabPanel
                gameType={gameType.OVERWATCH}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                gameprofileData={userProfileData?.data.profilesResponse.filter(
                  (item) => item.game === "OVERWATCH"
                )}
              /> */}
            <TabPanel p="16px" h="600px">
              <p>준비중...</p>
            </TabPanel>
            <TabPanel p="16px" h="600px">
              <p>준비중...</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
