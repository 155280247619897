import { Box, Heading, Image } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

export default function SideBar() {
  const location = useLocation();

  return (
    <Box
      minH="100vh"
      height="100%"
      borderRight="1px solid  var(--chakra-colors-gray-200)"
      textAlign="center"
    >
      <Box
        display="grid"
        gridTemplateRows="3fr 1fr 1fr"
        gap="16px"
        alignItems="center"
      >
        <Box as="a" href="/" margin="0 auto">
          <Image src="./teamdiv_logo.png" />
        </Box>
        <Heading
          color={
            location.pathname === "/"
              ? "teal"
              : "var(--chakra-colors-chakra-body-text)"
          }
        >
          <Box as="a" href="/">
            프로필
          </Box>
        </Heading>
        <Heading
          color={
            location.pathname === "/auction"
              ? "teal"
              : "var(--chakra-colors-chakra-body-text)"
          }
        >
          <Box as="a" href="/auction">
            경매
          </Box>
        </Heading>
      </Box>
    </Box>
  );
}
